<br>

<div style="width: auto; overflow: auto">
    <app-common-list-table
            [request]="getRequest"
            [cols]="cols"
            [filtering]="filtering"
            [globalConditionalStyle]="globalConditionalStyle"
            [globalVisualTransform]="globalVisualTransform"
            [showExportCsv]="showCostesTareasFertiCsv"
            (emitSearchText)="updateFooter()">

        <ng-template #thEnd>
            <th>
                <p class="d-flex justify-content-center"
                   style="margin-bottom: 0">Acciones</p>
            </th>
        </ng-template>

        <ng-template #tdEnd let-data>
            <td>
                <app-action-buttons 
                    [hideEdit]="true" 
                    [hideDelete]="true"
                    (showRowButtonEvent)="show('info', data.data)">
                </app-action-buttons>
            </td>
        </ng-template>

        <ng-template #footer let-col>
            <div style="font-size: 0.75em; text-align: left" *ngIf="(['apellidos'].includes(col.field))">
                TOTAL:
            </div>
            <div style="font-size: 0.75em; text-align: right; font-weight: bold"
                 *ngIf="(['resultado'].includes(col.field))">
                {{moneyFormat(col.footer)}} €
            </div>
        </ng-template>

    </app-common-list-table>
</div>

<p-dialog
        *ngIf="canShow['info']"
        header="Detalle del registro"
        [(visible)]="canShow['info']"
        (onShow)="scrollToTop()">

    <div style="width: 80vw !important">
        <app-form-builder
                [fields]="formFields"
                [distributionColumns]="4"
                [distributionOrder]="distributionOrder"
                [model]="model">
        </app-form-builder>
       
        
            <div class="d-flex justify-content-center" style="margin-top: 40px">
                <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
            </div>
    </div>
    <br>

</p-dialog>

