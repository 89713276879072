import {DashboardService} from '../../../../../service/events/dashboard.service';
import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseForm} from '../../../../base-form';
import {FincasApiService} from '../../../../../service/api/fincas-api.service';
import {InputType} from '../../../../../common/components/form-builder/form-builder.component';
import {build, list, pool} from '../../../../../common/classes/request-builder';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import {StorageManager} from '../../../../../common/storage-manager.class';
import { environment } from '../../../../../../environments/environment';
import { RequestButtonComponent } from 'src/app/common/components/request-button/request-button.component';

import type { OnInit } from '@angular/core';
import type { ItemInterface, TypeAheadInterface } from '../../../../../common/components/form-builder/form-builder.component';
import { SectorModel } from 'src/app/models/form-common/sector.model';
import { FincasModel } from 'src/app/models/form-common/fincas.module';

const sectorName = environment.features.sectorName;

@Component({
    selector: 'app-sector-form',
    templateUrl: './sector-form.component.html',
    styleUrls: ['./sector-form.component.scss']
})
export class SectorFormComponent extends BaseForm<SectorModel> implements OnInit {

    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
        requestButton = new RequestButtonComponent<SectorModel>();

    override pathToGoBack = 'sectores';
    public override model = {} as SectorModel;
    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public hasClients = environment.features.hasClients;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public sectorName = environment.features.sectorName;
    public showSectorPlantas = environment.features.showSectorPlantas;
    public formRequest = new AppFormRequest<SectorModel>();

    public distanciasGoteros: TypeAheadInterface<string> =  list(
        ['...', null],
        '25cm',
        '30cm',
        '50cm',
        '95cm',
        '100cm'
    );
    public fincas: TypeAheadInterface<FincasModel> = {
        selected: null,
        filtered: [{label: '...', value: null}],
        values: []
    };

    public formFields: ItemInterface<object>[] = [
        {
            field: 'id_finca',
            label: this.fincaName,
            inputType: {type: InputType.DROPDOWN},
            required: true,
            values: this.fincas,
            valuePrimaryKey: 'id'
        },
        {
            field: 'nombre', 
            label: `Nombre ${this.sectorName}`, 
            inputType: {type: InputType.EDIT_TEXT}, 
            required: true
        },
        {
            field: 'partida', 
            label: 'Partida', 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: environment.features.showPartida
        },
        {
            field: 'goteros', 
            label: 'Nº Goteros/Árbol',
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: this.appName !== 'gardengest'},
        {
            field: 'caudal_gotero', 
            label: 'Caudal Gotero (l/h)', 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: this.appName !== 'gardengest'
        },
        {
            field: 'caudal_cuba', 
            label: 'Capacidad Cuba (l)', 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: this.appName !== 'gardengest'
        },
        {
            field: 'caudal_inyectora', 
            label: 'Caudal Inyectora (l/h)', 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: this.appName !== 'gardengest'
        },
        {
            field: 'distancia_goteros', 
            label: 'Distancia entre Goteros', 
            inputType: {type: InputType.DROPDOWN}, 
            visible: this.appName === 'benibal', 
            values: this.distanciasGoteros
        }
        // {field: 'plantas', label: 'N˚ Árboles/Plantas', inputType: {type: InputType.EDIT_NUMERIC}, visible: this.showSectorPlantas},
    ];

    constructor(
        public override route: ActivatedRoute,
        public override router: Router,
        public override dashboard: DashboardService,
        public fincasApi: FincasApiService
    ) {
        super(
            route,
            router,
            dashboard,
            fincasApi.sectoresPUT,
            fincasApi.sectoresPOST,
            'sectores',
            'Actualizar ' + sectorName,
            'Crear ' + sectorName
        );
    }

    ngOnInit() {
        const fieldsToSend = (['id', 'id_usuario'])
            .concat(
                this.formFields.map(it => it.field ?? '')
            );
        if (this.hasClients) {
            fieldsToSend.push('id_cliente');
        }

        this.headerTitleWhenEdit = `Actualizar ${this.sectorName}`;
        this.headerTitleWhenNew = `Crear ${this.sectorName}`;

        this.expandFormRequest();

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setGetRequest(this.fincasApi.sectoresGET)
            .setPostRequest(this.fincasApi.sectoresPOST)
            .setPutRequest(this.fincasApi.sectoresPUT)
            .setFormFields(this.formFields)
            .setFieldsToSend(fieldsToSend);

        this.initFormData();
        
        this.formRequest.load();

        this.softInit(this.getType());
    }

    public submit() {
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }
        this.formRequest.send();
    }

    public formChanges() {
        this.requestButton.error = '';
    }

    private expandFormRequest() {
        this.formRequest.beforeSend(resolve => {
            if (this.hasClients) {
                const clientId = StorageManager.getUser().tipo === 'comunero' ? 
                    StorageManager.getUser().id_cliente : 
                    (StorageManager.getClient() || {}).id;              
                const clientIdFromFinca = (this.fincas.selected || {}).id_cliente;
                const clientIdFromForm = this.model.id_cliente;

                this.model.id_cliente =
                clientId ? clientId : // DEFAULT
                    clientIdFromFinca ? clientIdFromFinca :
                        clientIdFromForm ? clientIdFromForm : '';           
            }

            const userId = StorageManager.getUser().id;
            const userIdFromFinca = (this.fincas.selected || {}).id_usuario;
            const userIdFromForm = this.model.id_usuario;

            this.model.id_usuario =
                userIdFromFinca ? userIdFromFinca :
                    userIdFromForm ? userIdFromForm :
                        userId ? userId : '';

            if (this.hasClients) {
                this.model.id_usuario = this.applicationType === 'cropgest' ? 
                    (StorageManager.getClient() || {}).id_usuario : 
                    (StorageManager.getClient() || {}).id_usuario_asignado || this.model.id_usuario;
            }                        

            resolve(true);
        });

    }

    private initFormData(): Promise<boolean> {
      
        return pool([
            build(this.fincasApi.fincasGET, this.fincas, 'nombre')
        ], () => {
 
            this.formRequest.update();
            if (this.fincas.filtered.length === 2 && this.getType() === 0) {
                this.model.id_finca = this.fincas.filtered[1]?.value?.id ?? '';
            }
        });
    }
}