import { Component } from '@angular/core';
import { DashboardService } from '../../../../service/events/dashboard.service';
import { BaseView } from '../../../../view/base-view';
import { environment } from '../../../../../environments/environment';
import { VisitasApiService } from '../../../../service/api/visitas-api.service';
import { InputType } from '../../../../common/components/form-builder/form-builder.component';
import { Router } from '@angular/router';
import { RequestButtonTypes } from '../../../../common/components/request-button/request-button.component';
import { RequestHandler } from '../../../../service/OffService/request-handler';
import moment from 'moment';
import { Filtering } from '../../../../service/filtering/filtering';
import { Observable, firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { TrasiegoModel } from 'src/app/models/visitas/trasiego.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { ModelDatasetInternalInterface } from './model-dataset-internal.interface';
import { BaseResponseModel } from 'src/base-response.model';
import { TreeNode } from 'primeng/api';

interface Plaga {
    name: string;
    value: string;
}

@Component({
    selector: 'app-visitas-trasiego',
    templateUrl: './visitas-trasiego.component.html',
    styleUrls: ['./visitas-trasiego.component.scss']
})
export class VisitasTrasiegoComponent extends BaseView<TrasiegoModel> implements OnInit {

    /** VARIABLES ESTADISTICAS */
    public fecha = '';
    public loadingCharts = true;
    public notResults = false;
    public filter: Filtering<TrasiegoModel> = new Filtering<TrasiegoModel>();
    public showWarning = false;
    public basicOptions = {};
    public es = {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre',
            'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Borrar'
    };

    public chartShelly: ChartStructure | undefined;
    public finalChartShelly: ChartStructure | undefined;


    public list_devices: { label: string; value: number }[] = [];
    public list_device_obs$: Observable<{ label: string; value: number; }[]> | undefined;
    fecha_formatted = '';
    public realtime = false;

    /** FIN DE VARIEBLES ESTADISTICAS */

    public override filtering: Filtering<TrasiegoModel> = new Filtering<TrasiegoModel>();
    public trampasOptions: [{name: string, value: string | null}] = [{name: '...', value: null}];
  
    public fincaName = environment.features.fincaName;
    public parcelaName = environment.features.parcelaName;
    public fincasName = environment.features.fincasName;
  
    public deleteRequest: RequestHandler<TrasiegoModel> = this.visitasApi.visitasTrasiego.DELETE;
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;
    public getRequest = this.visitasApi.visitasTrasiego.GET;
    public menuItemId = 'visitas-trasiego';
  
    public currentWeek = {name: 'TODAS', value: ''};
    public currentYear = {name: 'TODOS', value: ''};
    public weeks: {name: string, value: number | null}[] = [];
    public years: {name: string, value: number | null}[] = [];
    public cols: ColInterface[] = [];
  

    public plagaSelected: Plaga[] = []; 

    public plagasList: Plaga[] = [
        {name: 'Trips', value: 'Trips'},
        {name: 'Empoasca', value: 'Empoasca'},
        {name: 'Stethorus', value: 'Stethorus'},
        {name: 'Sciara', value: 'Sciara'},
        {name: 'Scatella', value: 'Scatella'},
        {name: 'Parasitoides', value: 'Parasitoides'},
        {name: 'Pulgón', value: 'Pulgón'},
        {name: 'Otros', value: 'Otros'},
        {name: 'Mosca común', value: 'Mosca común'},
        {name: 'Mosquito', value: 'Mosquito'},
        {name: 'Mosca blanca', value: 'Mosca blanca'}
    ];

    public trampaSelected = {name: '...', value: null};
    public trampas: {name: string, value: string | null}[] = [
        {name: '...', value: null},
        {name: 'T.S1', value: 'T.S1'},
        {name: 'T.S4', value: 'T.S4'},
        {name: 'T.SEMILLERO', value: 'T.SEMILLERO'},
        {name: 'T.S5', value: 'T.S5'},
        {name: 'T.S7', value: 'T.S7'},
        {name: 'T.S8', value: 'T.S8'},
        {name: 'T.S10', value: 'T.S10'},
        {name: 'T.MICROS', value: 'T.MICROS'},
        {name: 'FC-T.S1', value: 'FC-T.S1'},
        {name: 'FC-T.S2', value: 'FC-T.S2'}
    ];

    public plagas: string[] = [];

    public dataPlagasChart: {labels: string[], datasets: ModelDatasetInternalInterface[]} = {
        labels: [],
        datasets: []
    };

    public formFields: ItemInterface<object>[] = [
        {
            label: 'Explotación',
            field: 'finca_nombre',
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Subparcela',
            field: 'parcela_nombre',
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Trampa',
            field: 'trampa',
            inputType: {type: InputType.TEXT}
        },
        {
            inputType: {type: InputType.EMPTY}
        },
        {
            label: 'Plaga #1',
            field: 'plaga1',
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Cantidad #1',
            field: 'cantidad1',
            inputType: {type: InputType.TEXT}
        },
        {
            inputType: {type: InputType.EMPTY}
        },
        {
            inputType: {type: InputType.EMPTY}
        },
        {
            label: 'Plaga #2',
            field: 'plaga2',
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Cantidad #2',
            field: 'cantidad2',
            inputType: {type: InputType.TEXT}
        },
        {
            inputType: {type: InputType.EMPTY}
        },
        {
            inputType: {type: InputType.EMPTY}
        },
        {
            label: 'Plaga #3',
            field: 'plaga3',
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Cantidad #3',
            field: 'cantidad3',
            inputType: {type: InputType.TEXT}
        },
        {
            inputType: {type: InputType.EMPTY}
        },
        {
            inputType: {type: InputType.EMPTY}
        },
        {
            label: 'Plaga #4',
            field: 'plaga4',
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Cantidad #4',
            field: 'cantidad4',
            inputType: {type: InputType.TEXT}
        },
        {
            inputType: {type: InputType.EMPTY}
        },
        {
            inputType: {type: InputType.EMPTY}
        },
        {
            label: 'Plaga #5',
            field: 'plaga5',
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Cantidad #5',
            field: 'cantidad5',
            inputType: {type: InputType.TEXT}
        },
        {
            inputType: {type: InputType.EMPTY}
        },
        {
            inputType: {type: InputType.EMPTY}
        },
        {
            label: 'Plaga #6',
            field: 'plaga6',
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Cantidad #6',
            field: 'cantidad6',
            inputType: {type: InputType.TEXT}
        },
        {
            inputType: {type: InputType.EMPTY}
        },
        {
            inputType: {type: InputType.EMPTY}
        },
    ];

    constructor(
        private router: Router,
        private dashboard: DashboardService,
        private visitasApi: VisitasApiService,
        private http: HttpClient
    ) {
        super(dashboard, visitasApi.visitasTrasiego.GET, visitasApi.visitasTrasiego.DELETE);

        this.currentYear = {name: moment().format('YYYY'), value: moment().format('YYYY')};
        this.getRequest.injectParams({ano: this.currentYear.value} as never, 'visitas/trasiego_get');

        const year1 = parseInt(moment().subtract(2, 'year').format('YYYY'), 10);
        const year10 = parseInt(moment().add(9, 'years').format('YYYY'), 10);

        this.weeks.push({name: 'TODAS', value: null});
        this.years.push({name: 'TODOS', value: null});

        for (let i = 1; i <= 54; i++) {
            this.weeks.push( {name: i.toString(), value: i} );
        }
        for (let i = year1; i <= year10; i++) {
            this.years.push( {name: i.toString(), value: i} );
        }
    }

    ngOnInit() {
        this.currentYear = {name: moment().format('YYYY'), value: moment().format('YYYY')};
        this.dashboard.setMenuItem(this.menuItemId);
        this.dashboard.setHeaderTitle('Visitas Trasiego');

        this.filtering.addFilter((copy: TreeNode<TrasiegoModel>[] | void) => (copy as TreeNode<TrasiegoModel>[]).filter(row => {
            const weekCond = this.currentWeek.value
                ? row.data?.semana === (this.currentWeek.value).toString()
                : true;
            const yearCond = this.currentYear.value
                ? row.data?.ano === (this.currentYear.value).toString()
                : true;
            return weekCond && yearCond;
        }));

        this.visitasApi.visitasPLAGAS_TRASIEGO_GET.toPromise()
            .then(plagas => {
                this.plagas = (plagas || []).map((it: TrasiegoModel) => it.plaga1 ?? '');
                this.visitasApi.visitasTrasiego.GET.response(() => {
                    this.formatTable();
                });
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );

        // this.fillCharts();
    }

    public filterValues() {
        this.filtering.filter();
    }

    public globalConditionalStyle(_value: string, cell: ColInterface): ColStylesInterface {
        if (!['finca_nombre', 'parcela_nombre', 'ano'].includes(cell.field)) {
            return {
                textAlign: 'center'
            };
        } else {
            return {};
        }
    }

    public globalVisualTransform(value: string, col: ColInterface, row: TreeNode<TrasiegoModel>): string {
    // Primero comprueba si la fila tiene alguna plaga (debería tener al menos una).
    // Luego, comprueba si existen las plagas de la 1 a la 4.
    // Si encuentra un valor Y ADEMÁS la columna coincide con el nombre de ese valor, devuelve LA CANTIDAD.

        const trasiego = row.data as TrasiegoModel;
        const hasPlagas = Object.keys(row).map(it => it.includes('plaga')).length > 0;

        if (hasPlagas) {
            for (const i of [1, 2, 3, 4, 5, 6]) {
                if (trasiego['plaga' + i] && (col.field === trasiego['plaga' + i])) {
                    return trasiego['cantidad' + i] as string;
                }
            }
        }
        return value;
    }

    public formatTable() {
        if (this.plagas && this.getRequest.value) {
            this.cols = [
                {field: 'finca_nombre', header: this.fincaName},
                {field: 'parcela_nombre', header: this.parcelaName},
                {field: 'semana', header: 'Semana'},
                {field: 'trampa', header: 'Trampa'},
            ];

            this.plagas.forEach(plaga => {
                this.cols.push({
                    field: plaga,
                    header: plaga
                });
            });
  
            this.filterValues();
        }
    }

    public edit(data: TrasiegoModel) {
        this.router.navigate(['dashboard', 'editar-visita-trasiego', data.id]);
    }

    public override show(modal: string, data: TrasiegoModel) {
        let x;

        this.model = data;
    
        if (modal !== 'grafica') {
            this.canShow[modal] = true;
        }

        if (modal === 'grafica'){
            try {
                if (this.trampaSelected.value != null && this.currentYear.value != null && this.plagaSelected.length > 0) {
                    this.fillCharts(this.currentWeek.value, this.trampaSelected.value, this.currentYear.value, this.plagaSelected);
                    this.canShow[modal] = true;
                }else{
                    this.showWarning = true;
                }
            } catch (error) {
                console.error(error);
        
                this.showWarning = true;
            }

        }

        for (x in this.canShow) {
            if (x !== modal && this.canShow[x]) {
                this.canShow[x] = false;
            }
        }
    }

    public override hide(modal: string) {
        this.model = {} as TrasiegoModel;
        this.canShow[modal] = false;
        if (this.deleteRequest) {
            this.deleteRequest.reset();
        }
    }

    public fillCharts(semana: string | null, trampa: string, año: string, plaga: Plaga[]) {

        let idsPlaga = '';

        for (const key in plaga) {
            if (Object.prototype.hasOwnProperty.call(plaga, key)) {
                const element = plaga[key];
                idsPlaga += element?.value + ';';
            }
        }

        let url = 
          environment.serverUrl + 
          'ws/index.php?p1=visitas&p2=generateGraphicsTrasiego&fecha=' + año + '&trampa=' + trampa + '&plaga=' + idsPlaga;
    
        if (semana != null) {
            url += '&semana=' + semana;
        }
  
        firstValueFrom(
            this.http.get<BaseResponseModel<TrasiegoModel>>
            (url)).then(async (resp: BaseResponseModel<TrasiegoModel>) => {

            const data = resp.data as Record<string, TrasiegoModel[]>;

            const modelDatasetGraphicsGeneral: {labels: string[], datasets: ModelDatasetInternalInterface[]} = {
                labels: [],
                datasets: []
            };

            for (const key in data) {
                if (Object.prototype.hasOwnProperty.call(data, key)) {
                    const dataPests = data[key];
          
                    if (dataPests) {
                        dataPests.sort((a: TrasiegoModel, b: TrasiegoModel) => +a.semana - +b.semana);
 
                        const modelDatasetInternal: ModelDatasetInternalInterface = {
                            label: 'namePests',
                            data: [],
                            fill: false,
                            borderColor: 'rgba(255,255,255)',
                            tension: 0.4
                        };
    
                        const weeks: string[] = [];
    
                        modelDatasetInternal.label = key;  
                        modelDatasetInternal.borderColor = ('#' + Math.floor(Math.random() * 16777215).toString(16));
              
                        for (const pest of dataPests) {
                            weeks.push( `Semana ${pest.semana}`);
                            modelDatasetInternal.data.push(pest.cantidad ?? '');
                        }
    
                        modelDatasetGraphicsGeneral.labels = weeks;      
                        modelDatasetGraphicsGeneral.datasets.push(modelDatasetInternal);
                    }
                }
            }

            this.dataPlagasChart = modelDatasetGraphicsGeneral;
            this.loadingCharts = false;
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );
    
    }
}


interface ChartStructure {
    labels: string[];
    datasets: DatasetsItems[];
}

interface DatasetsItems {
    label: string;
    data: number[];
    fill: boolean;
    borderColor?: string;
    tension?: number;
}