<br>

<div id="leyendas">
    <button (click)="myFilter('fecha_inscripcion', 0, 12)" style="background: none; border: none">
        <input readonly type="text" class="l-green">
        <span>Autorizado recientemente</span>
    </button>
    <button (click)="myFilter('fecha_caducidad', 0, 6)" style="background: none; border: none">
        <input readonly type="text" class="l-orange">
        <span>Caducado de 0 a 6 meses</span>
    </button>
    <button (click)="myFilter('fecha_caducidad', 6, 12)" style="background: none; border: none">
        <input readonly type="text" class="l-red">
        <span>Caducado de 6 a 12 meses</span>
    </button>
    <button (click)="myFilter('fecha_caducidad', 12, 99999)" style="background: none; border: none">
        <input readonly type="text" class="l-dark">
        <span>Caducado hace más de 12 meses</span>
    </button>
    <div style="margin-left: auto;" *ngIf="updateVademecum">
        <p style="
        color: #bb1c1c;
        font-weight: 700;
        font-size: 0.8rem;
        margin: 0;">*Datos en proceso de actualización </p>
  
      </div>
</div>


<div class="mr-2" style="width: 100%; min-height: 50vh; overflow-x: auto;">

<!-- VADEMECUM -->
<div *ngIf="isProductosVisible" class="d-flex flex-wrap align-items-center mb-2">
    <div *ngIf="showOrdenAplicacionProductos" class="me-2 mb-2">
        <button style="font-size: .7em; background-color: #16A2B7; color: white;"
                class="btn"
                (click)="mostrarOrdenAplicacion = true">
            Orden de aplicación de productos
        </button>
    </div>

    <div *ngIf="showVademecumMenuBotones" class="me-2 mb-2">
        <button style="font-size: .7em;"
                class="btn btn-primary"
                (click)="showExcepcionales()">
            Excepcionales
        </button>
    </div>

    <div *ngIf="showVademecumMenuBotones" class="me-2 mb-2">
        <button style="font-size: .7em;"
                class="btn btn-primary"
                (click)="showOtras()">
            Otras
        </button>
    </div>

    <div *ngIf="showVademecumMenuBotones" class="me-2 mb-2">
        <button style="font-size: .7em;"
                class="btn btn-primary"
                (click)="toggleUltimosCambiosFilter()">
            Cambios
        </button>
    </div>
    
    <div class="me-2 mb-2">
        <button style="font-size: .7em;"
                class="btn btn-outline-dark"
                (click)="resetSearch()">
            <i class="fa fa-refresh" aria-hidden="true"></i>
        </button>
    </div>

    <div class="usoagente-container me-2 mb-2" id="vademecum-panel">
        <p-multiSelect
                [maxSelectedLabels]="4"
                [showToggleAll]="false"
                (onChange)="search()"
                [defaultLabel]="usos.label"
                [options]="usos.filtered"
                optionLabel="label" 
                optionValue="value" 
                [filter]="true" 
                filterBy="label" 
                styleClass="custom-multiselect"
                [panelStyle]="{'max-height': '400px', 'width': '600px'}"
                [(ngModel)]="usos.selected">
        </p-multiSelect>
    </div>

    <div class="usoagente-container me-2 mb-2">
        <p-multiSelect
                [maxSelectedLabels]="4"
                [showToggleAll]="false"
                (onChange)="search()"
                [defaultLabel]="agentes.label"
                [options]="agentes.filtered"
                optionLabel="label" 
                optionValue="value" 
                [filter]="true" 
                filterBy="label" 
                [(ngModel)]="agentes.selected">
        </p-multiSelect>
    </div>
</div>
<div *ngIf="alertVademecum">
    <p style="
    color: #bb1c1c;
    font-weight: 700;
    font-size: 0.8rem;
    margin: 0;
    font-size: 12px;
    text-align: right;
    ">*Vademecum en proceso de adaptación al nuevo sistema de información del MAPA.
Para consultar fichas oficiales acceder a la siguiente dirección: 
<a href="https://servicio.mapa.gob.es/regfiweb/#" target="_blank" style=" color: #bb1c1c;"> Aquí</a> 

</p>
</div>
<div class="table-responsive">
    <!-- VADEMECUM -->
    <app-common-list-table
        [style.display]="isProductosVisible ? 'initial' : 'none'"
        [request]="formApi.magramaAll"
        [cols]="cols"
        [first]="first"
        [globalConditionalStyle]="globalConditionalStyle"
        [searchValue]="searchValue"
        (emitSearchText)="onSearchText($event)"
        [filtering]="filtering">

        <ng-template #thEnd>
            <th style="width: 125px;">
                <p class="mb-0 d-flex justify-content-center">Acciones</p>
            </th>
        </ng-template>

        <ng-template #tdEnd let-data>
            <td>
                <div class="d-flex justify-content-center">
                    <app-action-buttons
                        (showRowButtonEvent)="showInfo(data.data)"
                        [hideEdit]="true"
                        [hideDelete]="true"
                        [showNotification]="data?.actualizacion_reciente"
                        [notificationSeverity]="(data?.codigo_actualizacion === 'USOAGENTE_INSERT') ? 'primary' : 'danger'">
                    </app-action-buttons>
                    <div class="d-flex justify-content-center align-items-center" tooltipPosition="top" pTooltip="Ver ficha">
                        <a *ngIf="data" target="_blank" (click)="saveActivity(actionTypes.verFicha, data)"
                            [href]="'https://fichas-oficiales.s3.eu-west-3.amazonaws.com/' + data.data.referencia.trim() + '.pdf'">
                            <div class="d-flex justify-content-center align-items-center"
                                    style="
                                    height: 20px;
                                    width: 20px;
                                    border-radius: 3px;
                                    background-color: #007b00;
                                    margin-right: 10px;
                                    color: white;
                                    cursor: pointer;">
                                <i class="fa fa-file"></i>
                            </div>
                        </a>
                    </div>
                    <div *ngIf="showHistoricoFT" class="d-flex justify-content-center align-items-center">
                        <button class="d-flex justify-content-center align-items-center"
                                (click)="muestraHistorico(data)"
                                style="
                                height: 20px;
                                width: 20px;
                                border-radius: 3px;
                                background-color: #007b00;
                                margin-right: 10px;
                                color: white;
                                cursor: pointer;">
                            <i class="fa fa-calendar" tooltipPosition="top" pTooltip="Ver histórico"></i>
                        </button>
                    </div>
                </div>
            </td>
        </ng-template>
    </app-common-list-table>
</div>

<!-- AUTORIZACIONES EXCEPCIONALES -->
<app-common-list-table 
                    [style.display]="isExcepcionalesVisible ? 'initial' : 'none'"
                    [request]="excepcionalesApi.excepcionalesGET"
                    [cols]="colsExcep"
                    [filtering]="autorizFiltering"
                    [globalVisualTransform]="globalVisualTransform"
                    [globalConditionalStyle]="globalConditionalStyle"

                    [searchValue]="searchValueExcepcionales"
                    (emitSearchText)="onSearchText($event)">

    <ng-template #header>
        <div class="d-flex justify-content-start">
            <div style="margin-left: 10px; margin-right: 10px">
                <div class="btn-group">
                    <button (click)="filtrarTratamientos('vigente')"
                            [ngClass]="aplicado.selected === 'vigente' ? 'btn btn-primary' : 'btn btn-light'"
                            class="btn"
                            style="
                            font-size: .7em;
                            border: 1px solid grey;
                            border-right: 1px solid grey">
                        SI
                    </button>
                    <button (click)="filtrarTratamientos('no-vigente')"
                            [ngClass]="aplicado.selected === 'no-vigente' ? 'btn btn-primary' : 'btn btn-light'"
                            class="btn"
                            style="
                            font-size: .7em;
                            border: 1px solid grey;
                            border-right: 1px solid grey">
                        NO
                    </button>
                </div>
            </div>
            <span *ngIf="mostrarTratamientosAplicados"
                    style="color: white; margin-top: -0px;">
                Vigente
            </span>
            <div style="margin-left: 200px;">
                <button style="font-size: 0.8em;"
                        class="btn btn-primary"
                        (click)="showProductos()">
                    <i class="fa back" aria-hidden="true"></i>Productos Oficiales
                </button>
            </div>
            <div class="justify-content-end" style="text-align:right; margin:auto;">
                <span style="font-size:0.9em">Situación a {{fechaAutorizExcep}}</span>
            </div>
        </div>

    </ng-template>

    <ng-template #thEnd>
        <th>
            <p class="d-flex justify-content-center mb-0">Ficha</p>
        </th>
    </ng-template>

    <ng-template #tdEnd let-data>
        <td>
            <div class="d-flex justify-content-center align-items-center">
                <div class="d-flex justify-content-center align-items-center"
                        style="
                        height: 20px;
                        width: 20px;
                        border-radius: 3px;
                        background-color: #007b00;
                        margin-right: 10px;
                        color: white;
                        cursor: pointer;">
                    <a *ngIf="data"
                        [href]="'https://locatec.es/proyectos/fitogest/excepcionales/' + data.id + '.pdf'"
                        target="_blank">
                        <button class="btn btn-primary"><i class="fa fa-file-pdf-o"></i></button>
                    </a>
                </div>
            </div>
        </td>
    </ng-template>

</app-common-list-table>

<!-- OTRAS DENOMINACIONES -->
<app-common-list-table 
                    [style.display]="isOtrasDenominacionesVisible ? 'initial' : 'none'"
                    [request]="otrasDenominacionesApi.otrasDenominacionesGET"
                    [cols]="colsOtrasDenom"
                    [globalVisualTransform]="globalVisualTransform"
                    [globalConditionalStyle]="globalConditionalStyle"

                    [searchValue]="searchValueDenominaciones"
                    (emitSearchText)="onSearchText($event)">

    <ng-template #header>
        <div class="d-flex justify-content-start">

            <div style="margin-left: 200px;">
                <button style="font-size: 0.8em;"
                        class="btn btn-primary"
                        (click)="showProductos()">
                    <i class="fa back" aria-hidden="true"></i>Productos Oficiales
                </button>
            </div>
            <div class="justify-content-end" style="text-align:right; margin:auto;">
                <span style="font-size:0.9em">Situación a {{fechaOtrasDenom}}</span>
            </div>              
        </div>
    </ng-template>
  
</app-common-list-table>
</div>

<p-dialog
        *ngIf="canShow['info']"
        header="Detalle del registro"
        [(visible)]="canShow['info']"
        (onShow)="scrollToTop()">

    <div class="detalle-registro-container">
        <p class="mb-1" style="font-size: larger;">
            Producto
            <small *ngIf="!model?.actualizacion_reciente" class="float-right">
                <span *ngIf="model?.fecha_actualizacion">
                    Fecha de últimos cambios: <b>{{model.fecha_actualizacion}}</b>
                </span>
                <span *ngIf="!model?.fecha_actualizacion">
                    No hay cambios registrados
                </span>
            </small>
            <small *ngIf="model?.actualizacion_reciente && (model?.codigo_actualizacion !== 'USOAGENTE_INSERT')" class="text-danger float-right">
                <i class="fa fa-exclamation-circle"></i>
                Ha habido cambios recientes (<b>{{model.fecha_actualizacion || 'Fecha desconocida'}}</b>)
            </small>
            <small *ngIf="model?.actualizacion_reciente && (model?.codigo_actualizacion === 'USOAGENTE_INSERT')" class="text-primary float-right">
                <i class="fa fa-exclamation-circle"></i>
                Producto añadido recientemente (<b>{{model.fecha_actualizacion || 'Fecha desconocida'}}</b>)
            </small>
        </p>
        <table class="table detalle-producto">
            <thead>
                <th>Nº&nbsp;Reg.</th>
                <th>Nombre</th>
                <th>Titular</th>
                <th>Inscripción</th>
                <th>Caducidad</th>
                <th>Composición</th>
                <th>Estado</th>
            </thead>
            <tbody>
                <tr>
                    <td>{{model.referencia}}</td>
                    <td>{{model.nombre_comercial}}</td>
                    <td>{{model.titular}}</td>
                    <td>{{model.fecha_inscripcion}}</td>
                    <td>{{model.fecha_caducidad}}</td>
                    <td>{{model.composicion}}</td>
                    <td>{{model.estado}}</td>
                </tr>
                <tr>
                    <td><b>Condiciones Generales de Uso</b></td>
                    <td colspan="6"> {{model.condiciones_uso}} </td>
                </tr>
            </tbody>
        </table>

        <p class="mb-1" style="font-size: larger;">
            Usos y Agentes
        </p>

        <div style="height: 220px; overflow-y: auto; overflow-x: hidden;">
            <table class="table table-hover detalle-producto" id="fixed">
                <thead>
                    <th>Uso</th>
                    <th>Agente</th>
                    <th>Dosis&nbsp;Mín.</th>
                    <th>Dosis&nbsp;Máx.</th>
                    <th>Ud.&nbsp;medida</th>
                    <th>Plazo&nbsp;Seg.</th>
                    <th>Vol caldo</th>
                    <th>Nº aplicación</th>
                    <th>Intervalos</th>
                    <th>Instrucciones</th>
                </thead>
                <tbody *ngIf="model.usos">
                    <tr *ngFor="let uso of model.usos"
                        [class.bg-deleted]="uso?.codigo_actualizacion === 'USOAGENTE_DEL'"
                        [class.bg-added]="uso?.codigo_actualizacion === 'USOAGENTE_ADD'">
                        <td>{{uso?.uso}}</td>
                        <td>{{uso?.agente}}</td>
                        <td class="text-center">
                            {{uso?.dosis1}}
                            <b [style.visibility]="uso?.codigo_actualizacion === 'DOSIS_MIN' ? 'visible' : 'hidden'"
                                class="text-danger">*</b>
                        </td>
                        <td class="text-center">
                            {{uso?.dosis2}}
                            <b [style.visibility]="uso?.codigo_actualizacion === 'DOSIS_MAX' ? 'visible' : 'hidden'"
                                class="text-danger">*</b>
                        </td>
                        <td>
                            {{uso?.unidad_medida}}
                            <b [style.visibility]="(uso?.codigo_actualizacion === 'UNIDADES') && (uso?.fecha_actualizacion === fechaActualizacionMagrama) ? 'visible' : 'hidden'"
                                class="text-danger">*</b>
                        </td>
                        <td>
                            {{uso?.plazo_seguridad}}
                            <b [style.visibility]="(uso?.codigo_actualizacion === 'PLAZO_SEGURIDAD') && (uso?.fecha_actualizacion === fechaActualizacionMagrama) ? 'visible' : 'hidden'"
                                class="text-danger">*</b>
                        </td>

                        <td style="text-align: left;">
                            {{uso?.vol_caldo}}

                        </td>
                        <td style="text-align: center ;">
                            {{uso?.n_aplicaciones}}

                        </td>
                        <td style="text-align: center;">
                            {{uso?.intervalos}}
                            <td>
                                <div class="d-flex justify-content-center align-items-center" tooltipPosition="top"
                                pTooltip="Ver instrucciones">
                                <button class="d-flex justify-content-center align-items-center" (click)="muestraFormaEpoca(uso.forma_epoca)"
                                style="
                                height: 20px;
                                width: 20px;
                                border: none;
                                border-radius: 3px;
                                background-color: #007bff;
                                margin-right: 10px;
                                color: white;
                                cursor: pointer;">
                                    <i class="fa fa-info"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div *ngIf="!model.usos">Cargando...</div>

        <br>

        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
        </div>
    </div>
</p-dialog>

<p-dialog
        header="Histórico de Fichas Técnicas"
        [(visible)]="mostrarHistorico"
        (visibleChange)="ocultaHistorico($event)"
        (onShow)="scrollToTop()"
        (onClose)="deleteHistoricoData()">

    <div *ngIf="mostrarHistorico" class="d-flex flex-column justify-content-center align-items-center">
        <div class="mb-3 text-center">
            <span>Producto: {{nombreComercial}}</span>
            <br>
            <small class="text-secondary">Referencia: {{referenciaSel}}</small>
        </div>

        <div style="width: 450px; height: 300px;" class="mt-0 mb-3">
            <div>
                <app-form-builder
                    [model]="historicoModel"
                    [distributionColumns]="2"
                    [fields]="formFieldsHistorico"
                    (formChanges)="historicoFormChanges($event)">
                </app-form-builder>
            </div>
            <div *ngIf="!hasHistorico" class="mt-1 small alert alert-warning" style="font-size: .8rem;">
                No hay histórico de fichas para este producto.
            </div>

            <div *ngIf="fichaNoExiste" class="my-2 alert alert-info p-1" style="font-size: .7rem;">
                La ficha no está en nuestra base de datos o no ha cambiado desde la última revisión.
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-center align-items-center flex-column">
        <button style="font-size: 0.8em;"
                class="btn btn-primary"
                (click)="descargaFichaHistorico()"
                [disabled]="isDownloading || !this.historicoModel.anyo || !this.historicoModel.mes">
            {{isDownloading ? 'Comprobando...' : 'Descargar ficha'}}
        </button>
    </div>
</p-dialog>

<p-dialog
    header="Orden de aplicación de los productos"
    [(visible)]="mostrarOrdenAplicacion"
    (onShow)="scrollToTop()">
    <div class="px-2">
        <div>
            <a class="px-0 mb-2 btn btn-link btn-sm" target="_blank" href="https://www.mapa.gob.es/es/agricultura/temas/sanidad-vegetal/guiabuenaspracticasmezclasfinalcorregido_tcm30-57931.pdf">
                Guía de buenas prácticas para la mezcla en campo de productos fitosanitarios
                <i class="fa fa-external-link"></i>
            </a>
        </div>
        <img src="assets/other/orden_aplicacion_productos.jpg" alt="Orden de aplicación de los productos" />
        <div class="mt-3 d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="mostrarOrdenAplicacion = false">Cerrar</button>
        </div>
    </div>
</p-dialog>

<p-dialog header="Forma y época de aplicación" [(visible)]="show_forma_epoca" styleClass="dialog-forma-epoca"
    >
    <p > {{ forma_epoca }} </p>
</p-dialog>
