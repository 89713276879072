import { Component } from '@angular/core';
import { Filtering } from '../../../service/filtering/filtering';
import { RequestHandler } from '../../../service/OffService/request-handler';
import { RequestButtonTypes } from '../../../common/components/request-button/request-button.component';
import { InputType } from '../../../common/components/form-builder/form-builder.component';
import { DashboardService } from '../../../service/events/dashboard.service';
import { Router } from '@angular/router';
import { BaseView } from '../../base-view';
import { UsuariosApiService } from '../../../service/api/usuarios-api.service';
import { environment } from '../../../../environments/environment';
import {StorageManager} from '../../../common/storage-manager.class';
import { getBackNameCountry } from '../../../common/country';
import { createCsv } from '../../../common/createCsv';
import { UserApiService } from '../../../service/api/user-api.service';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../common/components/form-builder/form-builder.component';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { UserModel } from 'src/app/models/usuarios/user.model';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'app-usuarios',
    templateUrl: './usuarios.component.html',
    styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent extends BaseView<UserModel> implements OnInit {

    public showPerfilUnidades = environment.features.showPerfilUnidades;
    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public canRegister = environment.features.canRegister;
    public btnAnadir = 'Añadir Usuario';

    public filter: Filtering<UserModel> = new Filtering<UserModel>();
    public getRequest: RequestHandler<UserModel[]> = this.usuariosApi.usuariosGET;
    public deleteRequest: RequestHandler<UserModel> = this.usuariosApi.usuariosDELETE;
    public pathToForm = 'usuarios-form';
    public menuItemId = 'usuarios';
    public currentUser = StorageManager.getUser();

    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public override canShow: Record<string, boolean> = {};
    public override model = {} as UserModel;

    public formFieldsDatosCuenta: ItemInterface<object>[] = [
        {
            label: 'Nombre de Usuario', 
            name: 'Nombre de Usuario', 
            field: 'username', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Tipo', 
            name: 'Tipo', 
            field: 'tipo', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Acceso a Plataforma', 
            field: 'acceso_plataforma', 
            inputType: {type: InputType.TEXT}, 
            style: {'margin-top': '0px'} 
        },
    ];

    public formFieldsDatosPersonales: ItemInterface<object>[] = [

        {
            label: 'Nombre', 
            name: 'Nombre', 
            field: 'nombre', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Apellidos', 
            name: 'Apellidos', 
            field: 'apellidos', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'NIF\xa0/\xa0CIF', 
            name: 'NIF\xa0/\xa0CIF', 
            field: 'nif', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Email', 
            name: 'Email', 
            field: 'email', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Teléfono', 
            name: 'Teléfono', 
            field: 'telefono', 
            inputType: {type: InputType.TEXT}
        },
    ];

    public formFields: ItemInterface<object>[] = [
        {
            field: 'email', 
            label: 'Email', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'nombre', 
            label: 'Nombre', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'apellidos', 
            label: 'Apellidos', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'tipo_carnet', 
            label: 'Tipo de carnet', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'fecha_caducidad', 
            label: 'Fecha de caducidad', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'nif', 
            label: 'NIF', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'ropo', 
            label: 'ROPO', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'uds_medida',
            label: 'Unidad de medida',
            inputType: {type: InputType.TEXT},
            visible: this.showPerfilUnidades
        },
        {
            field: 'ratio_ha',
            label: 'Relación con una hectárea',
            inputType: {type: InputType.TEXT},
            visible: this.showPerfilUnidades
        },
        {
            field: 'tipo_mando',
            label: 'Tipo de mando',
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'manezylozano'
        },
        {
            field: 'imei', 
            label: 'IMEI', 
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'manezylozano'
        },
        {
            field: 'pais', 
            label: 'País', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'manezylozano'
        }
    ];

    public colsCanRegister: ColInterface[] = [
        {field: 'nombre', header: 'Nombre', filter: '', width: '200px'},
        {field: 'nif', header: 'NIF\xa0/\xa0CIF', filter: ''},
        {field: 'email', header: 'Email', filter: '', width: '200px'},
        {field: 'telefono', header: 'Teléfono', filter: ''},
        {field: 'tipo', header: 'Tipo', filter: '', width: '40px'},
    ];

    public cols: ColInterface[] = [
        {field: 'nombre', header: 'Nombre', filter: ''},
        {field: 'apellidos', header: 'Apellidos', filter: ''},
        {field: 'tipo_carnet', header: 'Carnet', filter: ''},
        {field: 'fecha_caducidad', header: 'Caducidad', filter: ''},
        {field: 'nif', header: 'NIF', filter: ''},
        {field: 'ropo', header: 'ROPO', filter: ''},
        {field: 'email', header: 'Email', filter: ''}
    ];

    constructor(public usuariosApi: UsuariosApiService,
        public userApi: UserApiService,
        private dashboard: DashboardService,
        private router: Router) {
        super(dashboard, usuariosApi.usuariosGET, usuariosApi.usuariosDELETE);
    }

    ngOnInit() {
        this.dashboard.setMenuItem(this.menuItemId);
    }

    public add() {
        this.router.navigate(['dashboard', 'crear-usuario']);
    }

    public edit(data: UserModel) {
        if (data.id === this.currentUser.id) {
            this.dashboard.openProfileDialog();
        } else {
            this.router.navigate(['dashboard', 'editar-usuario', data.id]);
        }
    }

    public globalVisualTransform(value: string, col: ColInterface, row: TreeNode<UserModel>) {
        const user = row.data as UserModel; 
        if (col.field === 'nombre'){
            if (user.apellidos === null) {
                user.apellidos = '';
            }
            return user.nombre + ' ' + user.apellidos;
        }
        if (col.field === 'tipo') {
            if (user.tipo === 'comunero' && (this.appName !== 'sequiador'  && this.appName !== 'SIEX')) {
                return 'agricultor';
            }
        }
        return value;
    }
    public globalConditionalStyle(
        _value: string, 
        _col: ColInterface, 
        _colGroup: ColInterface[], 
        row: TreeNode<UserModel>
    ): ColStylesInterface {
        const user = row.data as UserModel;
        if ( this.appName === 'manezylozano') {
            user.pais = (user.pais ? getBackNameCountry(user.pais) : '') ?? '';
        }
        return {};
    }

    public getStyles(property: string, data: UserModel) {
        if (property === 'acceso_plataforma' && data.acceso_plataforma) {
            switch (parseInt(data.acceso_plataforma, 10)) {
            case 0: return { backgroundColor: '#e91224' }; // Inactivo: rojo
            case 1: return { backgroundColor: '#34a835' }; // Activo: verde
            case 2: return { backgroundColor: '#007ad9' }; // Pendiente: azul
            }
        }
        return;
    }

    public toggleActive(data: UserModel) {
        if (confirm('¿Desea activar/desactivar este usuario? \n Este será notificado de ello por email.')) {
            if (data && data.acceso_plataforma) {
                if (data.acceso_plataforma === '2') {
                    data.acceso_plataforma = '1'; // Activo
                } else if (data.acceso_plataforma === '1') {
                    data.acceso_plataforma = '0'; // Inactivo
                } else {
                    data.acceso_plataforma = '1'; // Activo
                }
            }

            this.userApi.sendEmailConfirmation({
                usuario: data.username,
                email: data.email,
                estado: data.acceso_plataforma
            } as UserModel).then(res => {
                console.log(res);
                return;
            }
            ).catch (e => {
                console.log('catch en sendEmailConfirmation: ' + e);
            }
            );

            this.usuariosApi.usuariosPUT.perform({
                id: data.id,
                id_state: data.acceso_plataforma
            } as never);
            this.usuariosApi.usuariosPUT.response(res => {
                const respuesta: unknown = res;
                if ((respuesta as Response).status === 200) {
                    console.log('Success!');
                }
            });

        }
    }

    public async exportarUsuarios() {
        window.location.replace(environment.serverUrl + 'fito_backup/exportar_usuarios.php');
    }

    public async downloadCsv() {

        const arrayFormFields = [
            this.formFieldsDatosCuenta,
            this.formFieldsDatosPersonales
        ];
  
    
        const csvContent = createCsv(arrayFormFields, this.filter.value, 'nif' );
  
        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement | null;

        if (csvLink) {
            csvLink.download = 'Usuarios.csv';
            csvLink.href = encodeURI(csvContent);
            csvLink.click();
        }// if();
    }// ();
}
