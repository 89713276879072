import { Component } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { list } from 'src/app/common/classes/request-builder';
import { InputType } from 'src/app/common/components/form-builder/form-builder.component';
import { RequestButtonTypes } from 'src/app/common/components/request-button/request-button.component';
import { StorageManager } from 'src/app/common/storage-manager.class';
import { Utils } from 'src/app/common/utils';
import { RequestHandler } from 'src/app/service/OffService/request-handler';
import { TareasApiService } from 'src/app/service/api/tareas-api.service';
import { DashboardService } from 'src/app/service/events/dashboard.service';
import { Filtering } from 'src/app/service/filtering/filtering';
import { BaseView } from 'src/app/view/base-view';
import { environment } from 'src/environments/environment';

import type { AfterViewInit, OnInit } from '@angular/core';
import type { ItemInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { createCsv } from 'src/app/common/createCsv';
import { SubcontratoModel } from 'src/app/models/tareas/subcontrato.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { TreeNode } from 'primeng/api';
const TRUTHY_VALUES = [1, '1', true, 'true'];

@Component({
    selector: 'app-subcontratos',
    templateUrl: './subcontratos.component.html',
    styleUrls: ['./subcontratos.component.css']
})
export class SubcontratosComponent extends BaseView<SubcontratoModel> implements OnInit, AfterViewInit {

    public rolUser = StorageManager.getUser().rol;
    public hiddenButtonDelete = false;

    public appName = environment.appName;
    public fincaName = environment.features.fincaName;
    public hasClients = environment.features.hasClients;
    public serverUrl = environment.serverUrl;
    public userCanSeeClientes = this.hasClients &&
    (environment.features.userCanSeeClientes ? true : (StorageManager.getUser().rol === 'admin'));

    public getRequest: RequestHandler<SubcontratoModel[]> = this.tareasApi.subcontratos.GET;
    public deleteRequest: RequestHandler<SubcontratoModel> = this.tareasApi.subcontratos.DELETE;
    public pathToForm = 'crear-subcontratos';
    public menuItemId = 'crear-subcontratos';

    public override filtering: Filtering<SubcontratoModel> = new Filtering<SubcontratoModel>();
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public override canShow: Record<string, boolean> = {};
    public override model: SubcontratoModel = {};

    public distributionOrder = [3];
    public prioridad = list();
    public fecha: { inicio: string; fin: string } = {
        inicio: '',
        fin: '',
    };
    public fincas = list();
    public coste_total = 0;


    public cols: ColInterface[] = [
        { field: 'finca', header: this.fincaName, filter: '' },
        { field: 'fecha', header: 'Fecha', filter: '' },
        { field: 'proveedor', header: 'Proveedor', filter: '' },
        { field: 'concepto', header: 'Concepto', filter: '' },
        { field: 'coste', header: 'Coste', filter: '' }
    ];

    public formFields: ItemInterface<object>[] = [
        { 
            field: 'finca', 
            label: 'Finca', 
            inputType: { type: InputType.TEXT }, 
            values: this.fincas 
        },
        { 
            field: 'fecha', 
            label: 'Fecha', 
            inputType: { type: InputType.TEXT } 
        },
        { 
            field: 'proveedor', 
            label: 'Proveedor', 
            inputType: { type: InputType.TEXT }, 
            values: list(['...', null]) 
        },
        { 
            field: 'concepto', 
            label: 'Concepto', 
            inputType: { type: InputType.TEXT } 
        },
        { 
            field: 'coste', 
            label: 'Coste', 
            inputType: { type: InputType.TEXT } 
        },
    ];

    public totalHoras: string | string[] = '00:00';
    public auxTotalHoras: string | string[] = '00:00';

    constructor(public tareasApi: TareasApiService,
        private dashboard: DashboardService,
        private router: Router) {
        super(dashboard, tareasApi.subcontratos.GET, tareasApi.subcontratos.DELETE);


    }

    ngOnInit() {

        if (environment.features.showTareasMto) {
            if (this.rolUser !== 'admin') {
                this.hiddenButtonDelete = true;
            }
            this.prioridad.selected = 'No-Realizada';
        }

        this.dashboard.setSubmenuItem(this.menuItemId);

        this.filtering.addFilter((copy: TreeNode<SubcontratoModel>[] | void) => (copy as TreeNode<SubcontratoModel>[]).filter(row => {
            const subcontrato = row.data as SubcontratoModel;

            switch (this.prioridad.selected) {
            case 'Realizada':
                return ['Realizada'].includes(subcontrato.prioridad ?? '');
            case 'No-Realizada':
                return !['Realizada'].includes(subcontrato.prioridad ?? '');
            default:
                return true;
            }
        }));

        /*** 
     *  En el caso de que el usuario no se admin,
     * filtrará por su id de usuario que coincide,
     *  con el id trabajador   
    */
        if (StorageManager.getUser().rol !== 'admin') {
            this.filtering.addFilter((copy: TreeNode<SubcontratoModel>[] | void) => (copy as TreeNode<SubcontratoModel>[]).filter(row => {
                const subcontrato = row.data as SubcontratoModel;

                if (subcontrato.ids_trabajadores != null) {

                    const idTrabajador = StorageManager.getUser().id;
                    const listaTrabajadores = (subcontrato.ids_trabajadores as string).split(';');
                    if (listaTrabajadores.includes(idTrabajador)) {
                        return true;
                    }

                }
                return false;
            }));
        }

        /*** 
    *  Fin de filtrar por id_usuario = trabajador,
    *   
   */
        this.filtering.addFilter((copy: TreeNode<SubcontratoModel>[] | void) => 
            (copy as TreeNode<SubcontratoModel>[]).filter((row: TreeNode<SubcontratoModel>) => {
                const subcontrato = row.data as SubcontratoModel;
                if (this.fecha.inicio && this.fecha.fin && subcontrato.fecha) {

                    const arrayFecha = subcontrato.fecha.toString().split('/');
                    const fechaAux = arrayFecha[2] + '-' + arrayFecha[1] + '-' + arrayFecha[0];

                    if (
                        (moment(moment(this.fecha.inicio).format('YYYY-MM-DD')).diff(fechaAux) <= 0) && 
                    (moment(moment(this.fecha.fin).format('YYYY-MM-DD')).diff(fechaAux) >= 0)
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }

            }));
    }

    ngAfterViewInit() {
        this.tareasApi.subcontratos.GET.response(() => {
            ;

            this.updateFooter();

        });
    }

    public override sum(field: string) {
        let sum = 0;

        (this.filtering.value || []).forEach(it => {

            sum += (parseFloat(it.data?.[field]?.toString() ?? ''));

        });

        sum = Math.round(sum * 100) / 100;

        this.cols.forEach((it: ColInterface) => {
            if (it.field === field) {
                it.footer = sum + '';
            }
        });
        this.coste_total = sum;
    }

    public moneyFormat(input: string) {
        return Utils.decimalFormat(input, 2);
    }

    public override updateFooter() {
        ['coste'].forEach(it => this.sum(it));
        ['coste'].forEach(it => this.sum(it));
    }


    public globalConditionalStyle(value: string, col: ColInterface): ColStylesInterface {
        if (col.field) {
            return {
                textAlign: 'center'
            };
        }
        if (col.field === 'prioridad') {
            if (value === 'Alta') {
                return {
                    rowStyle: {
                        backgroundColor: '#f44242'
                    },
                };
            }
            else if (value === 'Realizada') {
                return {
                    rowStyle: {
                        backgroundColor: '#00ff00'
                    },
                };
            }
        }
        return {};
    }

    public globalVisualTransform(value: string, col: ColInterface) {
        const fieldsSiNo = ['limpieza_calles', 'bolsas_basura', 'limpieza_desagues'];
        if (fieldsSiNo.includes(col.field)) {
            if (TRUTHY_VALUES.includes(value)) {
                return 'Sí';
            } else {
                return 'No';
            }
        }
        return value;
    }


    public add() {
        this.router.navigate(['dashboard', this.pathToForm]);

    }

    public edit(data: SubcontratoModel) {
        this.router.navigate(['dashboard', 'editar-subcontratos', data.id]);
    }

    public duplicate(data: SubcontratoModel) {
        this.router.navigate(['dashboard', 'duplicar-subcontratos', data.id]);
    }

    public override deleteRegister() {
        if (this.model.id) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.safePerform();
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }

    public filtrarRealizados(value: string, from = 'prioridad') {
        if (from === 'prioridad') {
            this.prioridad.selected = this.prioridad.selected === value ? '' : value;
        } 

        this.filtering.filter();
    }

    public async downloadCsv() {

        const arrayFormFields = [
            this.formFields
        ];
      
        
        const csvContent = createCsv(arrayFormFields, this.filters.value, 'coste' );
      
        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement | null;

        if (csvLink) {
            csvLink.download = 'Subcontratos.csv';
            csvLink.href = encodeURI(csvContent);
            csvLink.click();
        }// if();
    }// ();
    
    getFechaInicio( event: string) {
        this.fecha.inicio = event;
        this.filtering.filter();
        this.updateFooter();
        
    }
    getFechaFin( event: string) {
        this.fecha.fin = event;
        this.filtering.filter();
        this.updateFooter();
    }
}
