import { Component } from '@angular/core';
import { DashboardService } from '../../../service/events/dashboard.service';
import { StockApiService } from '../../../service/api/stock-api.service';
import { environment } from 'src/environments/environment';
import { BaseView } from '../../base-view';
import { InputType } from 'src/app/common/components/form-builder/form-builder.component';
import { stack, build, list } from 'src/app/common/classes/request-builder';
import { FincasApiService } from 'src/app/service/api/fincas-api.service';
import { ProductosApiService } from 'src/app/service/api/productos-api';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { Filtering } from 'src/app/service/filtering/filtering';
import { Utils } from 'src/app/common/utils';
import {StorageManager} from '../../../common/storage-manager.class';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { StockageModel } from 'src/app/models/stock/stokage.model';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'app-balances',
    templateUrl: './balances.component.html',
    styleUrls: ['./balances.component.scss']
})
export class BalancesComponent extends BaseView<Record<string,string>> implements OnInit {

    public applicationType = environment.features.applicationType;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public showStockMarcaComercial = environment.features.showStockMarcaComercial;
    public showStockTipoDato = environment.features.showStockTipoDeDato;
    public showStockage = environment.features.showStockage;
    public showRoturaStock = environment.features.showFertiRoturaStock;
    public actionButtonText = this.showStockage
        ? 'Hoja de Stockage'
        : null;
  
    public override model = {
    // id_finca: '3',
    // fecha: moment('01/04/2019', 'DD/MM/YYYY').toDate()
    } as Record<string, string>;

    public filter: Filtering<Record<string,string>> = new Filtering<Record<string,string>>();

    public fechaStockage = {} as Record<string, string>;
    public tablaEntradas: Record<string, string>[] = [];
    public tablaStockage: StockageModel[] = [];
    public tablaStockageWithMarcas: Record<string, StockageModel> = {};
    public arrayStockageWithMarcas: StockageModel[] = [];
    public balances = list();
    public productos = list();
    public fincas = list();

    public isShowingStockage = false;
    public isPrinting = false;

    public formFieldsStockage: ItemInterface<object>[] = [
        {
            field: 'id_finca',
            label: this.fincaName,
            placeholder: 'TODOS',
            inputType: {type: InputType.DROPDOWN},
            values: this.fincas,
            valuePrimaryKey: 'id'
        },
        {
            field: 'fecha',
            label: 'Fecha',
            inputType: {type: InputType.CALENDAR},
            calendarView: 'month'
        }
    ];

    public cols: ColInterface[] = [
        {field: 'finca_nombre', header: this.fincaName},
        {field: 'tipo_de_producto', header: 'Tipo de producto', visible: this.showStockTipoDato},
        {field: 'producto_nombre', header: 'Producto'},
        {field: 'marca_comercial', header: 'Marca comercial', visible: this.showStockMarcaComercial},
        {field: 'stock_deficit', header: 'Rotura stock', visible: environment.appName === 'fotoagricultura'},
        {field: 'unidades_quedan', header: 'Unidades Quedan', footer: ''}
    ];

    constructor(public stockApi: StockApiService,
        private dashboardEvents: DashboardService,
        private productosApi: ProductosApiService,
        private fincasApi: FincasApiService) {
        super(dashboardEvents, stockApi.balances);
    }

    ngOnInit() {
        this.dashboardEvents.setSubmenuItem('stock-balances');
        this.dashboardEvents.clientChanges$.subscribe(() => {
            this.filter.filter();
        });

        this.filter.addFilter((copy: TreeNode<Record<string,string>>[] | void) => 
            (copy || []).filter(row => {
                if (this.applicationType === 'cropgest') {
                    if (StorageManager.getUser().tipo === 'comunero') {
                    // Extrañamente el id_cliente de esta finca se modifica en Locatec - Comunero al llegar aquí.
                        if (row.data?.['finca_nombre'] === 'RAGA') {
                            row.data['id_cliente'] = '132';
                        }
                        return row.data?.['id_cliente'] === StorageManager.getUser().id_cliente;
                    } else {
                        return true;
                    }
                } else{
                    const condCliente = StorageManager.getClient() ? (row.data?.['id_cliente'] === StorageManager.getClient().id) : true;
                    const condUsuario = row.data?.['id_usuario'] === StorageManager.getUser().id;
  
                    if (StorageManager.getUser().rol === 'admin') {
                        return condCliente;
                    } else {
                        return condCliente && condUsuario;
                    }
                }
            }));

        stack([
            build(this.stockApi.balances, this.balances, 'unidades', true),
            build(this.productosApi.productos.GET, this.productos, 'nombre', true),
            build(this.fincasApi.fincasGET, this.fincas, 'nombre'),
        ]).then(() => {
            this.fincas.filtered = this.fincas.filtered.filter(it => it && it.label);
            this.productos.filtered = this.productos.values.filter(it => it && it.value && (it.value.tipo === 'fertilizante'));
            this.updateFooter(this.filter, this.cols);
            return;
        }
        ).catch (e => {
            console.log('catch en stack: ' + e);
        }
        );
    }

    public formChanges() {
        if (this.model && this.model['id_finca'] && this.fincas.selected) {
            const idsProductos = (this.fincas.selected.ids_productos || '').split(';');
            this.productos.filtered = this.productos.values
                .filter(it => it && it.value && (it.value.tipo === 'fertilizante') && idsProductos.includes(it.value.id));
        }

        if (this.model && this.model['id_finca'] && this.model['fecha']) {
            this.generarStockage();
        }
    }

    public imprimirReceta() {
        this.isPrinting = true;
        const config: {canvasScale: number, orientation: 'p'|'l'; width: number; height: number} = {
            canvasScale: 2,
            orientation: 'p',
            width: 210,
            height: 290
        };

        html2canvas(
            document.getElementById('html2canvasDiv') ?? new HTMLElement(), 
            { scale: config.canvasScale, logging: false }
        ).then(canvas => {
            this.isPrinting = false;
            const pdf = new jsPDF(config.orientation, 'mm', 'a4');
            const data = canvas.toDataURL('image/png');

            pdf.addImage(data, 'png', 0, 0, config.width, (canvas.height / (canvas.width / config.width)), 'alias', 'FAST');
            pdf.save('Stockage y fertilizantes Finca ' + (this.fincas.selected || {} as FincasModel).nombre + ' '
        + this.fechaStockage['dia'] + '-' + this.fechaStockage['mes'] + '-' + this.fechaStockage['anyo']);
            return;
        }
        ).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );
    }

    public generarStockage() {
        if (!this.model['id_finca'] || !this.model['fecha']) {
            alert('Debe seleccionar una finca y una fecha.');
            return;
        }

        const fechaStockageRaw = moment(this.model['fecha']).add(1, 'month').subtract(1, 'day');
        this.fechaStockage = {
            dia: fechaStockageRaw.format('D'),
            mes: fechaStockageRaw.format('MMMM'),
            anyo: fechaStockageRaw.format('YYYY')
        };

        this.isShowingStockage = true;
        this.stockApi.stockage.GET.toPromise({
            id_finca: this.model['id_finca'],
            fecha: fechaStockageRaw.format('YYYY-MM-DD')
        } as never).then((res: StockageModel[]) => {
            this.productos.filtered = (this.productos.values).filter(it => it && it.value && (it.value.tipo === 'fertilizante'));

            const resEntradas = (res).filter(it => it && (it.tipo === 'E'));
            const resSalidas = (res).filter(it => it && (it.tipo === 'S'));
            this.tablaEntradas = [];
            this.tablaStockage = [];
            const productos = this.productos.filtered.map(it => it.value);
            const productosKeys = productos.map(it => it.id);

            (resEntradas).forEach(row => {
                const cantidades = (row.cantidades_productos || '').split(';');
                const marcas = (row.marcas_comerciales || '').split(';');

                (row.ids_productos || '').split(';').forEach((idProducto: string, i: number) => {
                    const idxProducto = productosKeys.indexOf(idProducto);
                    const productoOriginal = productos.find(it => it.id === idProducto);

                    if (idxProducto !== -1) {
                        if (!isNaN(parseFloat((cantidades[i] || '').replace(',', '.')))) {
                            if (row.tipo === 'E') {
                                if (productoOriginal) {
                                    this.tablaEntradas.push({
                                        id_producto: idProducto,
                                        fecha: row.fecha,
                                        proveedor: productoOriginal.fabricante,
                                        albaran: row.albaran || 'Sin nº de albarán',
                                        unidades: cantidades[i] ?? '',
                                        marca_comercial: marcas[i] ?? '',
                                        fertilizante: productoOriginal.nombre,
                                    });
                                }
                            }
                        }

                    }

                });
            });

            this.tablaStockage = JSON.parse(JSON.stringify(this.tablaEntradas));
            this.tablaStockage.map(it => {
                it.nombre = it.fertilizante ?? '';
                it.marca = it.marca_comercial ?? '';
                it.stockage_teorico = it.unidades ?? 0;
                it.stockage_real = it.unidades ?? 0;
                // Para evitar cantidades negativas incorrectas, al restar una salida,
                // si las unidades quedan en negativo, la cantidad sobrante se guarda en esta variable.
                // Para evitar cantidades negativas incorrectas, al restar una salida,
                // si las unidades quedan en negativo, la cantidad sobrante se guarda en esta variable.
                it.unidades_temp = it.unidades_temp || 0;
                return it;
            });

            (resSalidas).forEach(row => {
                const cantidades = (row.cantidades_productos || '').split(';');

                (row.ids_productos || '').split(';').forEach((idProducto: string, i: number) => {
                    const cantidad = parseFloat((cantidades[i] || '0,00').replace(',', '.'));
                    const productoAsociado = productos.find(it => it.id === idProducto);

                    if (productoAsociado) {
                        const ultimaEntradaAsociada = (this.tablaStockage || [])
                            .find(it => (it.id_producto === idProducto) && ((it.unidades ?? 0) > 0));
                        if (ultimaEntradaAsociada) {
                            const idxRegistro = (this.tablaStockage || []).indexOf(ultimaEntradaAsociada);

                            if ((idxRegistro !== -1) && !isNaN(cantidad)) {
                                ((this.tablaStockage[idxRegistro] as StockageModel).stockage_teorico as number) -= cantidad;
                                if ([1, '1'].includes(row.show_productos ?? '')) {
                                    ((this.tablaStockage[idxRegistro] as StockageModel).stockage_real as number) -= cantidad;
                                }
                                (this.tablaStockage[idxRegistro] as StockageModel).unidades -= cantidad;
    
                                if ((this.tablaStockage[idxRegistro] as StockageModel).unidades < 0) {
                                    ((this.tablaStockage[idxRegistro] as StockageModel).unidades_temp as number) -= 
                                        (this.tablaStockage[idxRegistro] as StockageModel).unidades;
                                    if (((this.tablaStockage[idxRegistro] as StockageModel).stockage_teorico as number) < 0) {
                                        (this.tablaStockage[idxRegistro] as StockageModel).stockage_teorico = 0;
                                    }
                                    if ([1, '1'].includes(row.show_productos ?? '')) {
                                        if (((this.tablaStockage[idxRegistro] as StockageModel).stockage_real as number) < 0) {
                                            (this.tablaStockage[idxRegistro] as StockageModel).stockage_real = 0;
                                        }
                                    }
                                }
    
                                // if (idProducto === '28') {
                                //   console.log(row);
                                //   console.log('Cantidad: ' + cantidades[i]);
                                //   console.log('Quedan ' + this.tablaStockage[idxRegistro].unidades + ' unidades.');
                                //   console.log(this.tablaStockage[idxRegistro].unidades_temp);
                                // }
    
                            } else {
                                // if (idProducto === '28') {
                                //   console.log(row);
                                //   console.log('Cantidad: ' + cantidades[i]);
                                // }
                                const productoSinMarca = 
                                  (this.tablaStockage || []).find(it => (it['id_producto'] === idProducto) && 
                                  (it['marca'] === 'Sin marca'));
                                if (productoSinMarca) {
                                    const idxProductoSinMarca = (this.tablaStockage || []).indexOf(productoSinMarca);
                                    if (!isNaN(cantidad)) {
                                        if (productoSinMarca) {
                                            if (idxProductoSinMarca !== -1) {
                                                ((this.tablaStockage[idxProductoSinMarca] as StockageModel)
                                                    .stockage_teorico as number) -= cantidad;
                                                if ([1, '1'].includes(row.show_productos ?? '')) {
                                                    ((this.tablaStockage[idxProductoSinMarca] as StockageModel)
                                                        .stockage_real as number) -= cantidad;
                                                }
                                            }
                                        } else {
                                            const toPush = Object.assign(
                                                {}, 
                                                (this.tablaStockage || []).find(it => (it['id_producto'] === idProducto))
                                            );
                                            if (toPush) {
                                                toPush['id_producto'] = idProducto;
                                                toPush['nombre'] = toPush['fertilizante'] ?? '';
                                                toPush['marca'] = 'Sin marca';
                                                toPush['stockage_teorico'] = toPush['unidades'] || 0;
                                                toPush['stockage_real'] = toPush['unidades'] || 0;
                                                this.tablaStockage.push(toPush);
                                            }
                                        }
                                    }
                                }    
                            }
    
                        }
                    }

                });
            });

            this.tablaEntradas = this.tablaEntradas.filter(it =>
                moment(it['fecha'], 'DD/MM/YYYY').format('MM/YYYY') === (moment(this.model['fecha']).format('MM/YYYY')));

            this.tablaStockageWithMarcas = {};
            this.tablaStockage.forEach(stockage => {
                // if (stockage.id_producto === '28') {
                //   console.log('Gastos a añadir: ' + stockage.stockage_teorico + '.');
                // }

                // if (stockage.id_producto === '28') {
                //   console.log('Gastos a añadir: ' + stockage.stockage_teorico + '.');
                // }

                stockage['stockage_teorico'] = 
                    parseFloat((stockage['stockage_teorico'] as number).toString()) - (stockage.unidades_temp as number);
                stockage['stockage_real'] = 
                    parseFloat((stockage['stockage_real'] as number).toString()) - (stockage.unidades_temp as number);
                
                const idx: string = stockage['id_producto'] + '_' + stockage['marca'];

                // if (stockage.id_producto === '28') {
                //   console.log('idx: ' + idx + '.');
                // }

                if (!(this.tablaStockageWithMarcas)[idx]) {
                    // if (stockage.id_producto === '28') {
                    //   console.log('Stockage estaba a 0 (acumulado: ' + stockage.unidades_temp + ').');
                    // }
                    this.tablaStockageWithMarcas[idx] = stockage;
                } else {
                    // if (stockage.id_producto === '28') {
                    //   console.log('Stockage estaba a ' + this.tablaStockageWithMarcas[idx].stockage_teorico
                    //     + ' (acumulado: ' + stockage.unidades_temp + ').');
                    // }
                    // if (stockage.id_producto === '28') {
                    //   console.log('Stockage estaba a ' + this.tablaStockageWithMarcas[idx].stockage_teorico
                    //     + ' (acumulado: ' + stockage.unidades_temp + ').');
                    // }
                    ((this.tablaStockageWithMarcas[idx] as StockageModel).stockage_teorico as number) += 
                    stockage['stockage_teorico'];
                    ((this.tablaStockageWithMarcas[idx] as StockageModel).stockage_real as number) += 
                    stockage['stockage_real'];
                }

                // if (stockage.id_producto === '28') {
                //   console.log('El balance es de: ' + this.tablaStockageWithMarcas[idx].stockage_teorico + '.\n');
                // }
            });
      
            this.arrayStockageWithMarcas = this.objectToArray(this.tablaStockageWithMarcas);
            return;
        }
        ).catch (e => {
            console.log('catch en stockApiStockage: ' + e);
        }
        );
    }

    public globalConditionalStyle(value: string | number, col: { field: string; }): ColStylesInterface {
        if (col.field === 'unidades_quedan' && +value < 0) {
            return {
                textAlign: 'center',
                color: 'red'
            };
        }
        if (col.field === 'unidades_quedan' || 
        col.field === 'producto_nombre' || 
        col.field === 'finca_nombre') {
            return {
                textAlign: 'center'
            };
        }

        if ( environment.appName === 'fotoagricultura') {
            if (col.field === 'stock_deficit' ) {
                if (value === 'Sí' ){
                    return {
                        rowStyle: {
                            color: 'black',
                            backgroundColor: '#ffcdc4',
                            borderBottom: '1px solid #dddddd'
                        },
                        textAlign: 'center'
                    };
                } else {
                    return {textAlign: 'center'};
                }
      
            }
        }
        return {};
    }

    public globalVisualTransform(value: string, col: ColInterface): string {
    
        if (col.field === 'unidades_quedan') {
            //return Utils.decimalFormat(value);
            return Number(value) >= 0 ? Utils.decimalFormat(value, 2) : '-' + Utils.decimalFormat(Math.abs(+value), 2) ;
        }
        return value;
    }

    public objectToArray(obj: Record<string, StockageModel>): StockageModel[] {
        const array: StockageModel[] = [];
        (Object.keys(obj) || []).forEach(key => {
            const value = obj[key];
            (value as StockageModel).id = key;

            if (value) {
                array.push(value);
            }
        });

        return array;
    }
}
