import { ItemInterface } from 'src/app/common/components/form-builder/form-builder.component';
import {Utils} from '../../common/utils';
import { TreeNode } from 'primeng/api';

export class Filtering<T> {

    public value: TreeNode<T>[] | void = [];
    public filtersToApply: ((arg: TreeNode<T>[] | void, arg1: Filtering<T> | void) => TreeNode<T>[] | void)[];

    
    private fullValue: TreeNode<T>[] = [];

    constructor() {
        this.filtersToApply = [];
    }

    public static replaceAll(str: string, search: string | RegExp, replacement: string): string {
        return str.replace(new RegExp(search, 'g'), replacement);
    }

    public static distinctBy<T>(value: T, index: number, self: T[]) {
        const ctx = this as ItemInterface<unknown>;
        return self.map((it: T) => (it as Record<string, string>)[ctx.field ?? ''])
            .indexOf((value as Record<string,string>)[ctx.field ?? '']) === index;
    }

    public static filterObjectCollection<T>(
        term: string, 
        value: T[], 
        searchOn?: string[], 
        searchPriority?: string[],
        mode?: 'or' | 'and'
    ) {
        const copy: T[] = [];
        mode = mode || 'and';
        console.log(mode);
        if (searchOn) {
            value.forEach(it => {
                const founds: number[] = [];
    
                for (const x in it) {
                    if (((searchOn || []).length === 0 || searchOn.indexOf(x) >= 0)
                        && Object.prototype.hasOwnProperty.call(it, x)
                        && it[x]) {
    
                        const preparedA = this.replaceAll(
                            (it as Record<string,string>)[x]?.toString().toLowerCase().trim() ?? '', '  ', ' '
                        );
                        const preparedAsplited = preparedA.split(' ');
                        const preparedB = this.replaceAll(term.toString().toLowerCase().trim(), '  ', ' ');
                        const preparedBsplited = preparedB.split(' ');
    
                        for (const prepared of preparedAsplited) {
                            const preparedAsub = prepared;
    
                            for (let j = 0; j < preparedBsplited.length; j++) {
                                const preparedBsub = preparedBsplited[j];
    
                                if (preparedAsub && preparedAsub.includes(preparedBsub ?? '')) {
                                    founds[j] = 1;
                                }
                            }
                        }
    
                        let totalFounds = 0;
                        founds.forEach((val: number) => totalFounds += val);
    
                        if (totalFounds === preparedBsplited.length) {
    
                            if ((searchPriority || []).includes(x)) {
                                copy.unshift(it);
                            } else {
                                copy.push(it);
                            }
    
                            break;
                        }
                    }
                }
            });   
        }

        return copy;
    }

    public static sort<T>(field?: string, order?: number){
        return (data1: T | string, data2: T | string) => {
            order = order || 1;

            let value1: string | number | undefined = field ? (data1 as Record<string,string>)[field] : (data1 as string);
            let value2: string | number | undefined = field ? (data2 as Record<string,string>)[field] : (data2 as string);
            let result = 0;

            if (value1 && value2) {
                const auxValue1 = Utils.toDate(value1.toString());
                const auxValue2 = Utils.toDate(value2?.toString());
    
                if (auxValue1 && auxValue2) {
                    value1 = auxValue1.toString();
                    value2 = auxValue2.toString();
                } else if (Utils.isNumber(value1.toString()) && Utils.isNumber(value2.toString())) {
                    value1 = parseFloat(value1.toString());
                    value2 = parseFloat(value2.toString());
                }
    
                if (value1 == null && value2 != null) {
                    result = -1;
                } else if (value1 != null && value2 == null) {
                    result = 1;
                } else if (value1 == null && value2 == null) {
                    result = 0;
                } else if (typeof value1 === 'string' && typeof value2 === 'string') {
                    result = value1.localeCompare(value2);
                } else {
                    result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
                }    
    
            }
            return order * result;
        };
    }
    
    public setValue(value: TreeNode<T>[]): Filtering<T> {
        this.fullValue = value;
        this.value = value;
        return this;
    }

    public addFilter(callback: (data: TreeNode<T>[] | void, self: Filtering<T> | void) => TreeNode<T>[] | void) {
        this.filtersToApply.push(callback);
    }

    public filter() {
        if (this.fullValue) {
            this.value = this.fullValue;
            this.filtersToApply.forEach(filter => {
                if (filter) {
                    this.value = filter(this.value, this);
                }
            });
        }
    }

    public distinct(value: string | undefined, index: number, self: (string | undefined)[]) {
        return self.indexOf(value ?? '') === index;
    }

    public distinctObjectCollection(values: TreeNode<T>[], field: string): (string | undefined)[] {
        if (values) {
            return values.map(it => (it.data as Record<string,string>)[field]).filter(this.distinct);
        } else {
            return [];
        }
    }

    public filterObjectCollection(
        term: string, 
        value: TreeNode<T>[], 
        searchOn?: string[], 
        searchPriority?: string[], 
        mode?: 'or' | 'and'
    ) {
        return Filtering.filterObjectCollection(term, value, searchOn, searchPriority, mode);
    }

    public replaceAll(str: string, search: string | RegExp, replacement: string): string {
        return str.replace(new RegExp(search, 'g'), replacement);
    }


    public like(a: string, b: string): boolean {
        a = a || '';
        b = b || '';

        return this.replaceAll(a.toString().toLowerCase().trim(), '  ', ' ')
            .includes(this.replaceAll(b.toString().toLowerCase().trim(), '  ', ' '));
    }

}
