import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FileUploadModule } from 'primeng/fileupload';
import { list, stack } from '../../../../common/classes/request-builder';
import { InputType } from '../../../../common/components/form-builder/form-builder.component';
import { RequestButtonTypes } from '../../../../common/components/request-button/request-button.component';
import { Utils } from '../../../../common/utils';
import { EntidadesApiService } from '../../../../service/api/entidades-api.service';
import { TransaccionesApiService } from '../../../../service/api/transacciones-api.service';
import { DashboardService } from '../../../../service/events/dashboard.service';
import { Filtering } from '../../../../service/filtering/filtering';
import { RequestHandler } from '../../../../service/OffService/request-handler';
import { BaseView } from '../../../../view/base-view';
import { environment } from '../../../../../environments/environment';
import { StorageManager } from '../../../../common/storage-manager.class';
import { fillTipoTransaccion } from './transacciones-form/tipo_transaccion';
import moment from 'moment';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { TransaccionModel } from 'src/app/models/reto/transaccion.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { UserModel } from 'src/app/models/usuarios/user.model';
import { TypeAheadInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { EntidadModel } from 'src/app/models/reto/entidad.model';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { TreeNode } from 'primeng/api';

const TRUTHY_VALUES = [1, '1', true, 'true'];
@Component({
    selector: 'app-transacciones',
    templateUrl: './transacciones.component.html',
    styleUrls: ['./transacciones.component.scss']
})
export class TransaccionesComponent extends BaseView<TransaccionModel> implements OnInit {

    public appName = environment.appName;
    public currentUser = StorageManager.getUser();
    public serverUrl = environment.serverUrl;
    public hasClients = environment.features.hasClients;
    public userCanSeeClientes = this.hasClients &&
    (environment.features.userCanSeeClientes ? true : (StorageManager.getUser().rol === 'admin'));
    public clienteName = environment.features.clienteName;
    public canGenFile: boolean = 
        this.appName !== 'ctl' ? 
            true : (StorageManager.getUser().nombre.includes('oberto') || 
      StorageManager.getUser().nombre.includes('ocatec')  ) ? true : false;

    public FileUpload: FileUploadModule | undefined;
    public getRequest: RequestHandler<TransaccionModel[]> = this.transaccionesApi.TransaccionesGET;
    public deleteRequest: RequestHandler<TransaccionModel> = this.transaccionesApi.TransaccionesDELETE;
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public menuItemId = 'reto-transacciones';
    public filter: Filtering<TransaccionModel> = new Filtering<TransaccionModel>();

    public ropo_responsable: TypeAheadInterface<string> = list();

    /* lista de años por defecto */
    public year = list();

    public deleteWarningMessage = '';
    public deleteWarning = '';

    public parcelasAux = null;

    public formFields: ItemInterface<object>[] = [
        { 
            field: 'fecha', 
            label: 'Fecha', 
            inputType: {type: InputType.TEXT}, 
            required: true 
        },
        {
            field: 'id_entidad_origen',
            label: 'Entidad responsable ',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'ropo_origen',
            label: 'ROPO responsable',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'tipo_transaccion',
            label: 'Tipo transacción ',
            inputType: {type: InputType.TEXT},
        
        },
        {
            field: 'id_entidad_destino',
            label: 'Entidad destino',
            inputType: {type: InputType.TEXT}
        },  
        {   
            field: 'ropo_destino', 
            label: 'ROPO destino', 
            inputType: {type: InputType.TEXT},
        },
    ];
    public formAlmacenFields: ItemInterface<object>[] = [
        { 
            field: 'descuadre', 
            label: 'Descuadre', 
            inputType: {type: InputType.SWITCH}
        },  
        { 
            field: 'almacen_origen', 
            label: 'Nº almacén responsable', 
            inputType: {type: InputType.TEXT}
        },
        { 
            field: 'almacen_destino', 
            label: 'Nº almacén de transacción', 
            inputType: {type: InputType.TEXT}
        }
    ];

    public formProductoFields: ItemInterface<object>[] = [
        {
            field: 'id_producto',
            label: 'Producto ' ,
            inputType: {type: InputType.TEXT},
        },
        { 
            field: 'num_envases', 
            label: 'Nº envases', 
            inputType: {type: InputType.TEXT}
        },
        { 
            field: 'capacidad_envase', 
            label: 'Capacidad de envase', 
            inputType: {type: InputType.TEXT}
        },
        { 
            field: 'volumen_total', 
            label: 'Volumen total', 
            inputType: {type: InputType.TEXT}
        },
        { 
            field: 'lote', 
            label: 'Lote', 
            inputType: {type: InputType.TEXT}
        },
        { 
            field: 'importacion_paralela', 
            label: 'Importación paralela', 
            inputType: {type: InputType.RO_SWITCH}
        }
    ];

    public formCultivoFields: ItemInterface<object>[] = [
        { 
            field: 'cultivo_tratamiento', 
            label: 'Objeto de tratamiento', 
            inputType: {type: InputType.TEXT}
        },
    ];

    public formNIMAFields: ItemInterface<object>[] = [
        { 
            field: 'nima', 
            label: 'NIMA', 
            inputType: {type: InputType.EDIT_TEXT}
        }
    ];

 
    public generarFields: ItemInterface<object>[] = [
        {
            field: 'fecha_inicio',
            label: 'Fecha inicio',
            inputType: {type: InputType.CALENDAR}
        },
        {
            field: 'fecha_fin',
            label: 'Fecha fin',
            inputType: {type: InputType.CALENDAR}
        },
        {
            field: 'ropo_responsable',
            label: 'Ropo responsable',
            values: this.ropo_responsable,
            inputType: {type: InputType.DROPDOWN_SEARCH}
        }
    ];

    public cols: ColInterface[] = [
        { field: 'fecha', header: 'Fecha ', width: '80px' },
        { field: 'tipo_transaccion', header: 'Tipo Transacción', filter: '' },
        { field: 'id_entidad_origen', header: 'Entidad Responsable', filter: '' },
        { field: 'ropo_origen', header: 'ROPO Responsable', filter: '', width: '120px' },
        { field: 'id_entidad_destino', header: 'Entidad Destino', filter: '', width: '150px' },
        { field: 'ropo_destino', header: 'ROPO Destino', filter: '', width: '120px' },
        { field: 'id_producto', header: 'Producto', filter: '', width: '150px' },

    ];

    public user: UserModel = StorageManager.getUser();
    public fecha_inicio: Date = new Date();
    public fecha_fin: Date = new Date();
    public modelGenFields = {
        fecha_inicio: this.fecha_inicio,
        fecha_fin: this.fecha_fin,
        ropo_responsable: this.ropo_responsable
    } as TransaccionModel;

    public es = {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: [
            'enero', 
            'febrero', 
            'marzo', 
            'abril', 
            'mayo', 
            'junio', 
            'julio', 
            'agosto', 
            'septiembre', 
            'octubre', 
            'noviembre', 
            'diciembre'
        ],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Borrar'
    };

    public tipo_transaccion: TypeAheadInterface<string> = fillTipoTransaccion();

    constructor(
        public transaccionesApi: TransaccionesApiService,
        public entidadesApi: EntidadesApiService,
        public dashboard: DashboardService,
        private router: Router
    ) {
        super(dashboard, transaccionesApi.TransaccionesGET, transaccionesApi.TransaccionesDELETE);

    }


    async ngOnInit() {
        this.dashboard.setSubmenuItem(this.menuItemId);
        stack([

        ]).then(() => {

            this.filter.filter();
            return;
        }
        ).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );
        if (this.appName === 'innovia' && this.currentUser.rol === 'user'){
            this.filter.addFilter((data: TreeNode<TransaccionModel>[] | void) => (data || [])
                .filter((it: TreeNode<TransaccionModel>) => it)
                .filter((it) => this.currentUser.id_cliente === it.data?.id_cliente)
            );
        }// if();

        const entidades = await this.entidadesApi.EntidadesGET.toPromise();

        entidades.forEach((entidad: EntidadModel) => {
            let ropo = '';
            if (entidad.roposs !== null && entidad.roposs !== ' '){
                ropo = entidad.roposs ?? '';
            }
            if (entidad.ropost !== null  && entidad.ropost !== ' '){
                ropo = entidad.ropost ?? '';
            }
            if (entidad.roposu !== null  && entidad.roposu !== ''){
                ropo = entidad.roposu ?? '';
            }
            this.ropo_responsable.filtered.push({label : entidad.nombre + ' - ' + ropo, value: ropo});
            this.ropo_responsable.values.push({label : entidad.nombre + ' - ' + ropo, value: ropo});
        });
    // this.filter.addFilter(data => {
    //   return (data || []).filter(finca => finca && finca.id);
    // });
    }


    public add() {
        /*     if (!StorageManager.getClient() && this.userCanSeeClientes) {
      this.dashboard.openClientDialog();
    } else { */
        this.router.navigate(['dashboard', 'reto-crear-transacciones']);
        /*     } */
    }

    public edit(data: TransaccionModel) {
        this.router.navigate(['dashboard', 'reto-editar-transacciones', data.id]);
    }

    public duplicate(data: TransaccionModel) {
    
        this.router.navigate(['dashboard', 'reto-duplicar-transacciones', data.id]);
    }

    public override deleteRegister() {
        if (this.model.id) {
            if (this.deleteWarning) {
                this.deleteWarning = '';
                this.deleteWarningMessage = '';

                this.deleteRequest.safePerform(this.model);
                this.deleteRequest.response(() => {
                    if (!this.deleteRequest.hasError) {
                        this.getRequest.safePerform();
                    }
                });
            } else {
                this.deleteWarningMessage =
          'Se borrará toda la información asociada';
                this.deleteWarning = '¡Entidad con datos! ¿Continuar?';
            }
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }

    public globalConditionalStyle(
        _value: string, 
        _col: ColInterface, 
        _colGroup: ColInterface[], 
        row: TreeNode<TransaccionModel>
    ): ColStylesInterface {

        // if (Utils.isNumber(value) || col.field === 'telefono') {
        //   return {
        //     textAlign: 'center',
        //     marginRight: '60px'
        //   };
        // }
        // if (col.field === 'provincia') {
        //   return {
        //     textAlign: 'center'
        //   };
        // }
        const transaccion = row.data as TransaccionModel;

        transaccion.num_envases = Utils.decimalFormat(transaccion.num_envases + '', 2, '.', ',', 3);
        transaccion.capacidad_envase = Utils.decimalFormat(transaccion.capacidad_envase + '', 2, '.', ',', 3);
        transaccion.volumen_total = Utils.decimalFormat(transaccion.volumen_total + '', 2, '.', ',', 3);
        return {};
    }

    public formChanges() {
    }

    emptyDates(){
        this.fecha_inicio = new Date();
        this.fecha_fin = new Date();
    }


    public generarArchivo() {
        if (!this.modelGenFields.fecha_inicio || !this.modelGenFields.fecha_fin || !this.ropo_responsable.selected) {
            alert('Debe especificar todos los campos.');
            return;
        }
  
        const retoFields = '?fecha_inicio=' +  moment(this.modelGenFields.fecha_inicio).format('YYYY-MM-DD') +
    '&fecha_fin=' + moment(this.modelGenFields.fecha_fin).format('YYYY-MM-DD') +
    '&ropo_resposable=' + this.ropo_responsable.selected;
  
        window.open((this.serverUrl + 'ws/reto/getReto.php' + retoFields), '_blank');
    }

  
    public checkAlmacen() {

        if ( 
            this.model.tipo_transaccion != null &&  
          (this.model.tipo_transaccion === '11' || this.model.tipo_transaccion === '12' ) 
        ) {
            if ( this.model.id_entidad_origen != null && this.model.id_entidad_destino != null ) {
                if ( this.model.ropo_origen != null && this.model.ropo_destino != null ) {
                    if (  
                        (this.model.id_entidad_origen  === this.model.id_entidad_destino )  && 
                      (this.model.ropo_origen  === this.model.ropo_destino ) ) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
        
            return false;
        }

    }

    public checkNIMA() {
        return (this.model.tipo_transaccion === '9' || this.model.tipo_transaccion === '10') ? true : false;
    }

    public override show(modal: string, data: TransaccionModel) {
        super.show(modal, data);
        this.model.importacion_paralela = TRUTHY_VALUES.includes(this.model.importacion_paralela ?? '');
    }
}