import { Component } from '@angular/core';
import { DashboardService } from 'src/app/service/events/dashboard.service';
import { BaseView } from 'src/app/view/base-view';
import { CitasApiService } from 'src/app/service/api/citas-api.service';
import { environment } from 'src/environments/environment';
import { VisitasApiService } from 'src/app/service/api/visitas-api.service';
import { InputType } from 'src/app/common/components/form-builder/form-builder.component';
import { Router } from '@angular/router';
import { RequestButtonTypes } from 'src/app/common/components/request-button/request-button.component';
import { RequestHandler } from 'src/app/service/OffService/request-handler';
import moment from 'moment';
import { Filtering } from 'src/app/service/filtering/filtering';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { VisitasSemanaModel } from 'src/app/models/visitas/visitas-semana.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'app-visitas-semana',
    templateUrl: './visitas-semana.component.html',
    styleUrls: ['./visitas-semana.component.scss']
})
export class VisitasSemanaComponent extends BaseView<VisitasSemanaModel> implements OnInit {

    public fincaName = environment.features.fincaName;
    public parcelaName = environment.features.parcelaName;
    public fincasName = environment.features.fincasName;

    public deleteRequest: RequestHandler<VisitasSemanaModel> = this.visitasApi.visitasSemanaDELETE;
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;
    public getRequest = this.citasApi.visitasSemanaGET;
    public menuItemId = 'visitas-semana';
    public override filtering: Filtering<VisitasSemanaModel> = new Filtering<VisitasSemanaModel>();

    public plagas: string[] = [];

    public currentWeek = {name: 'TODAS', value: ''};
    public currentYear = {name: 'TODOS', value: ''};
    public weeks: {name: string, value: number | null}[] = [];
    public years: {name: string, value: number | null}[] = [];

    public cols: ColInterface[] = [];

    public formFields: ItemInterface<object>[] = [
        {
            label: 'Explotación',
            field: 'finca_nombre',
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Subparcela',
            field: 'parcela_nombre',
            inputType: {type: InputType.TEXT}
        },
        {
            inputType: {type: InputType.EMPTY}
        },
        {
            inputType: {type: InputType.EMPTY}
        },
        {
            label: 'Plaga #1',
            field: 'plaga1',
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Umbral #1',
            field: 'umbral1',
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Cantidad #1',
            field: 'cantidad1',
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Observaciones #1',
            field: 'observaciones1',
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Plaga #2',
            field: 'plaga2',
            inputType: {type: InputType.TEXT},
        },
        {
            label: 'Umbral #2',
            field: 'umbral2',
            inputType: {type: InputType.TEXT},
        },
        {
            label: 'Cantidad #2',
            field: 'cantidad2',
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Observaciones #2',
            field: 'observaciones2',
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Plaga #3',
            field: 'plaga3',
            inputType: {type: InputType.TEXT},
        },
        {
            label: 'Umbral #3',
            field: 'umbral3',
            inputType: {type: InputType.TEXT},
        },
        {
            label: 'Cantidad #3',
            field: 'cantidad3',
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Observaciones #3',
            field: 'observaciones3',
            inputType: {type: InputType.TEXT}
        },

        {
            label: 'Plaga #4',
            field: 'plaga4',
            inputType: {type: InputType.TEXT},
        },
        {
            label: 'Umbral #4',
            field: 'umbral4',
            inputType: {type: InputType.TEXT},
        },
        {
            label: 'Cantidad #4',
            field: 'cantidad4',
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Observaciones #4',
            field: 'observaciones4',
            inputType: {type: InputType.TEXT}
        },

        {
            label: 'Plaga #5',
            field: 'plaga5',
            inputType: {type: InputType.TEXT},
        },
        {
            label: 'Umbral #5',
            field: 'umbral5',
            inputType: {type: InputType.TEXT},
        },
        {
            label: 'Cantidad #5',
            field: 'cantidad5',
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Observaciones #5',
            field: 'observaciones5',
            inputType: {type: InputType.TEXT}
        },

        {
            label: 'Plaga #6',
            field: 'plaga6',
            inputType: {type: InputType.TEXT},
        },
        {
            label: 'Umbral #6',
            field: 'umbral6',
            inputType: {type: InputType.TEXT},
        },
        {
            label: 'Cantidad #6',
            field: 'cantidad6',
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Observaciones #6',
            field: 'observaciones6',
            inputType: {type: InputType.TEXT}
        },
    ];

    constructor(
        private router: Router,
        private dashboard: DashboardService,
        private citasApi: CitasApiService,
        private visitasApi: VisitasApiService
    ) {
        super(dashboard, citasApi.visitasSemanaGET, visitasApi.visitasSemanaDELETE);

        const year1 = parseInt(moment().subtract(1, 'year').format('YYYY'), 10);
        const year10 = parseInt(moment().add(9, 'years').format('YYYY'), 10);

        this.weeks.push({name: 'TODAS', value: null});
        this.years.push({name: 'TODOS', value: null});

        for (let i = 1; i <= 54; i++) {
            this.weeks.push( {name: i.toString(), value: i} );
        }
        for (let i = year1; i <= year10; i++) {
            this.years.push( {name: i.toString(), value: i} );
        }
    }

    ngOnInit() {
        this.currentYear = {name: moment().format('YYYY').toString(), value: moment().format('YYYY').toString()};
        this.dashboard.setMenuItem(this.menuItemId);

        this.filtering.addFilter((copy: TreeNode<VisitasSemanaModel>[] | void) => (copy as TreeNode<VisitasSemanaModel>[]).filter(row => {
            const visita = row.data as VisitasSemanaModel;

            const weekCond = this.currentWeek.value
                ? visita.semana === (this.currentWeek.value).toString()
                : true;
            const yearCond = this.currentYear.value
                ? visita.ano === (this.currentYear.value).toString()
                : true;
            return weekCond && yearCond;
        }));

        this.visitasApi.visitasPLAGAS_GET.toPromise()
            .then(plagas => {
                this.plagas = (plagas || []).map((it: VisitasSemanaModel) => it.plaga1 ?? '');
                this.getRequest.response(() => {
                    this.formatTable();
                });
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
    }

    public filterValues() {
        this.filtering.filter();
    }

    public globalConditionalStyle(_value: string, cell: ColInterface): ColStylesInterface {
        if (!['finca_nombre', 'parcela_nombre', 'ano'].includes(cell.field)) {
            return {
                textAlign: 'center'
            };
        } else {
            return {};
        }
    }

    public globalVisualTransform(value: string, col: ColInterface, row: TreeNode<VisitasSemanaModel>): string {
    // Primero comprueba si la fila tiene alguna plaga (debería tener al menos una).
    // Luego, comprueba si existen las plagas de la 1 a la 4.
    // Si encuentra un valor Y ADEMÁS la columna coincide con el nombre de ese valor, devuelve LA CANTIDAD.

        const visita = row.data as VisitasSemanaModel;

        const hasPlagas = Object.keys(row).map(it => it.includes('plaga')).length > 0;

        if (hasPlagas) {
            for (const i of [1, 2, 3, 4, 5, 6]) {
                if (visita['plaga' + i] && (col.field === visita['plaga' + i])) {
                    // return row['cantidad' + i];
                    return '×';
                }
            }
        } else {
            return value;
        }
        return '';
    }

    public formatTable() {
        if (this.plagas && this.getRequest.value) {
            this.cols = [
                {field: 'finca_nombre', header: this.fincaName},
                {field: 'parcela_nombre', header: this.parcelaName},
                {field: 'semana', header: 'Semana'},
            ];

            this.plagas.forEach(plaga => {
                this.cols.push({
                    field: plaga,
                    header: plaga
                });
            });
  
            this.filterValues();
        }
    }

    public edit(data: VisitasSemanaModel) {
        this.router.navigate(['dashboard', 'editar-visita-semana', data.id]);
    }

}
