<br>

<div style="width: auto; overflow: auto">
  <app-common-list-table
          [request]="getRequest"
          [cols]="this.tipoAplicacion === 'viverogest' ? colsViverogest : cols"
          [filtering]="filter"
          [globalVisualTransform]="globalVisualTransform"
          [customExportCsv]="showTareasBioExportCsv"
          actionButtonText="Añadir Tarea"
          (actionButtonClick)="add()">
          <ng-template #header>
            <div *ngIf="this.rolUser ==='responsable' || this.appName === 'benibal'" style="margin-left: 20px;">
              <button class="mx-2 btn" (click)="downloadCsv()" style="padding: 5px; font-size: .8rem; background-color: #16A2B7; color: white;">
                  <i class="fa fa-download"></i>
                  Exportar listado
              </button>
            </div>
              
            <div  *ngIf="showCampaign" id="campaign">
              <label for="" >Campaña</label>
              <p-select [options]="years" [(ngModel)]="selectedYears" (onChange)='selectedCampaign()' ></p-select>
          </div>
          </ng-template>
    <ng-template #thEnd>
      <th>
        <p class="d-flex justify-content-center"
           style="margin-bottom: 0">Acciones</p>
      </th>
    </ng-template>

    <ng-template #tdEnd let-data>
      <td>
        <app-action-buttons
                [showDuplicate]="true"
                (duplicateRowButtonEvent)="duplicate(data.data)"        
                (editRowButtonEvent)="edit(data.data)"
                (deleteRowButtonEvent)="show('delete', data.data)"
                (showRowButtonEvent)="show('info', data.data)">
        </app-action-buttons>
      </td>
    </ng-template>
  </app-common-list-table>
</div>

<p-dialog
  *ngIf="canShow['info']"
  header="Detalle del registro"
  [(visible)]="canShow['info']"
  (onShow)="scrollToTop()">

  <div style="width: 80vw !important">
    <app-form-builder
            [fields]="this.tipoAplicacion === 'viverogest' ? formFieldsViverogest : formFields"
            [distributionColumns]="4" 
            [distributionOrder]="[4,3,3,3,3,3]"
            [model]="model">
    </app-form-builder>
    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
    </div>
  </div>
  <br>

</p-dialog>

<p-dialog
  *ngIf="canShow['delete']"
  header="Eliminar registro"
  [(visible)]="canShow['delete']"
  (onShow)="scrollToTop()">

  <div style="width: 80vw !important">
    <app-form-builder
            [fields]="formFields"
            [distributionColumns]="4" 
            [distributionOrder]="[4,3,3,3,3,3]"
            [model]="model">
    </app-form-builder>
    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
      <app-request-button
              (click)="deleteRegister()"
              [request]="deleteRequest"
              [mode]="deleteRequestButtonType">
      </app-request-button>
    </div>
    <br>
    <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading"
       style="color: red; text-align: center">
      ¿Está seguro de eliminar este registro?
    </p>
  </div>
  <br>

</p-dialog>

<!-- Modal filtrar campaña -->
<p-dialog [contentStyle]="{'min-height':'250px'}" header="Campaña"   
    [closeOnEscape]="false" 
    [closable]="false" 
    [(visible)]="modalShowCampaign" 
    (onShow)="scrollToTop()"
    >

    <div>
        <div style="max-width: 200px;" hei>
            <p-select [options]="years" [(ngModel)]="selectedYears" 
            (onChange) = 'selectedCampaign()'
            ></p-select>        </div>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="closeModalShowCampaign();">Cerrar</button>
        </div>
        <br>
    </div>
</p-dialog>