import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {VentasApiService} from '../../../../service/api/ventas-api.service';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {Utils} from '../../../../common/utils';
import {Filtering} from '../../../../service/filtering/filtering';
import {BaseView} from '../../../base-view';
import {StorageManager} from '../../../../common/storage-manager.class';
import { environment } from '../../../../../environments/environment';
import { FincasApiService } from '../../../../service/api/fincas-api.service';
import { ParcelasModel } from '../../../../models/form-common/parcelas.model';
import { createCsv } from '../../../../common/createCsv';

import type { OnInit, AfterViewInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { VentaModel } from 'src/app/models/ventas/venta.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';

@Component({
    selector: 'app-cargas',
    templateUrl: './ventas.component.html',
    styleUrls: ['./ventas.component.scss']
})

export class VentasComponent extends BaseView<VentaModel> implements OnInit, AfterViewInit {
    public appName = environment.appName;
    public ventaName = environment.features.ventaName;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public parcelaName = environment.features.parcelaName;
    public parcelasName = environment.features.parcelasName;
    public canAddVentas = environment.features.canAddVentas;
    public showVentasComercio = environment.features.showVentasComercio;
    public showClientesCooperativa = environment.features.showClientesCooperativa;
    public hasClients = environment.features.hasClients;
    public userCanSeeClientes = this.hasClients &&
        (environment.features.userCanSeeClientes ? true : (StorageManager.getUser().rol === 'admin'));
        
    public activateApiSIEX = environment.features.activateSIEX;

    public btnAnadir = `Añadir ${this.ventaName}`;

    public distributionOrder = [
        3, 3, 3
    ];
    public esUsuario: boolean = StorageManager.getUser().rol !== 'admin';
    public filter: Filtering<VentaModel> = new Filtering<VentaModel>();
    public getRequest: RequestHandler<VentaModel[]> = this.ventasApi.ventasGET;
    public deleteRequest: RequestHandler<VentaModel> = this.ventasApi.ventasDELETE;
    public pathToForm = 'ventas-form';
    public menuItemId = 'ventas';
    public parcelas: ParcelasModel[] = [];
    public parcelasFilter: ParcelasModel[] = [];
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public showVentasLote = environment.features.showVentasLote;
    public override canShow: Record<string, boolean> = {};
    public override model: VentaModel = {};

    public formFields: ItemInterface<object>[] = [
        {
            field: 'finca', 
            name: this.fincaName, 
            label: this.fincaName, 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'sector', 
            name: 'Sector', 
            label: 'Sector', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'parcelas', 
            name: this.parcelaName, 
            label: this.parcelasName, 
            inputType: {type: InputType.TEXTAREA}, 
            disabled: true
        },
        {
            field: 'fecha', 
            name: 'Fecha', 
            label: 'Fecha', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'albaran', 
            name: 'Nº Albarán', 
            label: 'Nº Albarán', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'lote', 
            name: 'Nº Lote', 
            label: 'Nº Lote', 
            inputType: {type: InputType.TEXT}, 
            visible: this.showVentasLote
        },
        {
            field: 'comprador', 
            name: this.appName === 'esparragosgranada' ? 'Cooperativa' : 
                this.appName === 'covidai' ? 'Proveedor' : 
                    'Comercio', label: this.appName === 'esparragosgranada' ? 'Cooperativa' : 
                this.appName === 'covidai' ? 'Proveedor' : 
                    'Comercio', 
            inputType: {type: InputType.TEXT}, 
            visible: this.showVentasComercio
        },
        {
            field: 'producto', 
            name: 'Producto', 
            label: 'Producto', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'pyf'
        },   
        {
            field: 'cultivo', 
            name: 'Cultivo', 
            label: 'Cultivo', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName !== 'pyf'
        },
        {
            field: 'variedad', 
            name: 'Variedad', 
            label: 'Variedad', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName !== 'pyf'
        },
        {
            field: 'cantidad', 
            name: 'Cantidad Kg', 
            label: 'Cantidad Kg', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'precio', 
            name: 'Precio', 
            label: 'Precio', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'precio_kg', 
            name: this.appName === 'fotoagricultura' ? 'Precio/kg' : 'Importe', 
            label: this.appName === 'fotoagricultura' ? 'Precio/kg' : 'Importe', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'descuento_porcentaje', 
            label: 'Descuento %', 
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'agrosol'
        },
        {
            field: 'descuento_euros', 
            label: 'Descuento €', 
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'agrosol'
        },
        {
            field: 'importe_total', 
            label: 'Importe Total', 
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'agrosol'
        },
    ];

    public cols: ColInterface[] = [
        // {field: 'cooperativa', header: 'Cooperativa', filter: ''},
        {
            field: 'finca', 
            header: this.fincaName, 
            filter: ''
        },
        {
            field: 'fecha', 
            header: 'Fecha', 
            filter: ''
        },
        {
            field: 'albaran', 
            header: 'Nº\xa0Albarán', 
            filter: ''
        },
        {
            field: 'comprador', 
            header: this.appName === 'esparragosgranada' ? 'Cooperativa' : this.appName === 'covidai' ? 'Proveedor' : 'Comercio', 
            filter: '', 
            visible: this.showVentasComercio
        },
        {
            header: 'Producto', 
            field: 'producto', 
            visible: this.appName === 'pyf'
        },
        {
            field: environment.features.hasPlantas ? 'cultivo_legacy' : 'cultivo', 
            header: 'Cultivo', 
            filter: '', 
            visible: this.appName !== 'pyf'
        },
        {
            field: environment.features.hasPlantas ? 'variedad_legacy' : 'variedad', 
            header: 'Variedad', 
            filter: '', 
            visible: this.appName !== 'pyf'
        },
        {
            field: 'cantidad', 
            header: 'Cantidad\xa0Kg', 
            footer: ''
        },
        {
            field: 'precio', 
            header: 'Precio', 
            filter: ''
        },
        {
            field: 'precio_kg', 
            header: this.appName === 'fotoagricultura' ? 'Precio/kg' : 'Importe', 
            filter: '',
            visible: this.appName !== 'agrosol'
        },
        {
            field: 'importe_total', 
            header:'Importe', 
            filter: '',
            visible: this.appName === 'agrosol',
            footer: ''
        }
    ];

    constructor(public fincasApi: FincasApiService,
        public ventasApi: VentasApiService,
        private dashboard: DashboardService,
        private router: Router) {
        super(dashboard, ventasApi.ventasGET, ventasApi.ventasDELETE);
    }

    ngOnInit() {
        this.dashboard.setSubmenuItem(this.menuItemId);

        this.fincasApi.parcelasGET.safePerform();
        this.fincasApi.parcelasGET.response(parcelas => {
            this.parcelas = parcelas;
        });
    }

    ngAfterViewInit() {
        this.ventasApi.ventasGET.response(() => {
            this.updateFooter();
        });
    }
    
    public override show(modal: string, data: VentaModel) {
        super.show(modal, data);

        this.formatParcelas();
    }

    formatParcelas() {
        let parcelasId: string[];
        if (this.model.ids_parcelas) {
            parcelasId = this.model.ids_parcelas.split(';') || [];
        } else {
            parcelasId = [];
        }
        if (parcelasId.length > 0) {
            this.parcelas.forEach(parcela => {
                if (parcelasId.includes(parcela.id.toString())) {
                    this.parcelasFilter.push(parcela);
                }
            });
            this.model.parcelas = '';
            this.parcelasFilter.forEach(parcela => {
                if (parcela) {
                    this.model.parcelas += `- ${parcela.nombre}\n`;
                }
            });
            this.model.parcelas = this.model.parcelas.substring(0, this.model.parcelas.length - 1);
        } else {
            this.model.parcelas = [];
        }
    }

    public add() {
        if (!StorageManager.getClient() && this.userCanSeeClientes) {
            this.dashboard.openClientDialog();
        } else {
            this.router.navigate(['dashboard', 'crear-venta']);
        }
    }

    public edit(data: VentaModel) {
        this.router.navigate(['dashboard', 'editar-venta', data.id]);
    }

    public duplicate(data: VentaModel) {
        this.router.navigate(['dashboard', 'duplicar-venta', data.id]);
    }    


    public globalConditionalStyle(value: string, col: ColInterface): ColStylesInterface {
        if (Utils.isNumber(value) || col.field === 'cantidad' || col.field === 'albaran' ) {
            return {
                textAlign: 'right',
                paddingRight: '60px',
            };
        }
        if ( col.field !== 'finca') {
            return {
                textAlign: 'center'
            };
        }
        return {};
    }

    public globalVisualTransform(value: string, col: { field: string; }) {
        if (Utils.isNumber(value)) {
            if (col.field !== 'albaran') {
                return Utils.decimalFormat(value.replace(',', '.'), 2);
            }
        }
        if (col.field === 'variedad' || col.field === 'cultivo') {
            return (value?.length > 25) ? value.slice(0, 25 - 1) + '…' : value;
        }
        return value;
    }

    public override sum(field: string) {
        let sum = 0;

        (this.filter.value || []).forEach(it => {
            if ((it as VentaModel)[field]?.toString()) {
                sum += parseFloat((it as VentaModel)[field]?.toString() ?? '');
            }
        });

        this.cols.forEach(it => {
            if (it.field === field) {
                if (field === 'cantidad') {
                    it.footer = Utils.decimalFormat(sum + '', 0);
                } else {
                    it.footer = Utils.decimalFormat(sum + '', 2);
                }
            }
        });
    }

    public override updateFooter() {
        ['cantidad'].forEach(it => this.sum(it));

        if (this.appName === 'agrosol') {
            ['importe_total'].forEach(it => this.sum(it));
        }
    }


    public async downloadCsv() {

        const arrayFormFields = [
            this.formFields
        ];
      
        
        const csvContent = createCsv(arrayFormFields, this.filter.value, 'precio_kg' );
      
        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement;

        csvLink.download = 'Ventas.csv';
        csvLink.href = encodeURI(csvContent);
        csvLink.click();
    }// ();
}