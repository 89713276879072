import {Injectable} from '@angular/core';
import {RequestHandler} from '../OffService/request-handler';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { SectorModel } from 'src/app/models/form-common/sector.model';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';

@Injectable({
    providedIn: 'root'
})
export class FincasApiService {

    public fincasGET = new RequestHandler<FincasModel[]>();
    public fincasDELETE = new RequestHandler<FincasModel>();
    public fincasPUT = new RequestHandler<FincasModel[]>();
    public fincasPOST= new RequestHandler<FincasModel[]>();

    public sectoresGET = new RequestHandler<SectorModel[]>();
    public sectoresDELETE = new RequestHandler<SectorModel>();
    public sectoresPUT = new RequestHandler<SectorModel[]>();
    public sectoresPOST = new RequestHandler<SectorModel[]>();

    public parcelasGET = new RequestHandler<ParcelasModel[]>();
    public parcelasDELETE = new RequestHandler<ParcelasModel>();
    public parcelasPUT = new RequestHandler<ParcelasModel[]>();
    public parcelasPOST = new RequestHandler<ParcelasModel[]>();


    constructor(
        private syncServiceFinca: SyncService<FincasModel[]>,
        private syncServiceFincaDelete: SyncService<FincasModel>,
        private syncServiceSector: SyncService<SectorModel[]>,
        private syncServiceSectorDelete: SyncService<SectorModel>,
        private syncServiceParcela: SyncService<ParcelasModel[]>,
        private syncServiceParcelaDelete: SyncService<ParcelasModel>,
        private apiFinca: ApiRequestService<FincasModel[]>,
        private apiFincaDelete: ApiRequestService<FincasModel>,
        private apiSector: ApiRequestService<SectorModel[]>,
        private apiSectorDelete: ApiRequestService<SectorModel>,
        private apiParcela: ApiRequestService<ParcelasModel[]>,
        private apiParcelaDelete: ApiRequestService<ParcelasModel>) {

        this.defineFincasGET();
        this.defineFincasPUT();
        this.defineFincasPOST();
        this.defineFincasDELETE();

        this.defineSectoresGET();
        this.defineSectoresPUT();
        this.defineSectoresPOST();
        this.defineSectoresDELETE();

        this.defineParcelasGET();
        this.defineParcelasPUT();
        this.defineParcelasPOST();
        this.defineParcelasDELETE();
    }

    private defineFincasGET() {
        this.fincasGET
            .fetch(HttpRequestType.GET, 'fincas/get');

        this.apiFinca
            .registerRequest(this.fincasGET);

        this.syncServiceFinca
            .register(this.syncServiceFinca.baseSyncPolicy(this.fincasGET));
    }

    private defineFincasPUT() {
        this.fincasPUT
            .fetch(HttpRequestType.PUT, 'generic/fincas');

        this.apiFinca
            .registerRequest(this.fincasPUT);

        this.syncServiceFinca
            .register(this.syncServiceFinca.baseSyncPolicy(this.fincasPUT));
    }

    private defineFincasPOST() {
        this.fincasPOST
            .fetch(HttpRequestType.POST, 'generic/fincas');

        this.apiFinca
            .registerRequest(this.fincasPOST);

        this.syncServiceFinca
            .register(this.syncServiceFinca.baseSyncPolicy(this.fincasPOST));
    }

    private defineFincasDELETE() {
        this.fincasDELETE
            .fetch(HttpRequestType.DELETE, 'generic/fincas');

        this.apiFincaDelete
            .registerRequest(this.fincasDELETE);

        this.syncServiceFincaDelete
            .register(this.syncServiceFincaDelete.baseSyncPolicy(this.fincasDELETE));
    }

    private defineSectoresGET() {
        this.sectoresGET
            .fetch(HttpRequestType.GET, 'sectores/get');

        this.apiSector
            .registerRequest(this.sectoresGET);

        this.syncServiceSector
            .register(this.syncServiceSector.baseSyncPolicy(this.sectoresGET));
    }

    private defineSectoresPUT() {
        this.sectoresPUT
            .fetch(HttpRequestType.PUT, 'generic/sectores');

        this.apiSector
            .registerRequest(this.sectoresPUT);

        this.syncServiceSector
            .register(this.syncServiceSector.baseSyncPolicy(this.sectoresPUT));
    }

    private defineSectoresPOST() {
        this.sectoresPOST
            .fetch(HttpRequestType.POST, 'generic/sectores');

        this.apiSector
            .registerRequest(this.sectoresPOST);

        this.syncServiceSector
            .register(this.syncServiceSector.baseSyncPolicy(this.sectoresPOST));
    }

    private defineSectoresDELETE() {
        this.sectoresDELETE
            .fetch(HttpRequestType.DELETE, 'generic/sectores');

        this.apiSectorDelete
            .registerRequest(this.sectoresDELETE);

        this.syncServiceSectorDelete
            .register(this.syncServiceSectorDelete.baseSyncPolicy(this.sectoresDELETE));
    }

    private defineParcelasGET() {
        this.parcelasGET
            .fetch(HttpRequestType.GET, 'parcelas/get');

        this.apiParcela
            .registerRequest(this.parcelasGET);

        this.syncServiceParcela
            .register(this.syncServiceParcela.baseSyncPolicy(this.parcelasGET));
    }

    private defineParcelasPUT() {
        this.parcelasPUT
            .fetch(HttpRequestType.PUT, 'generic/parcelas');

        this.apiParcela
            .registerRequest(this.parcelasPUT);

        this.syncServiceParcela
            .register(this.syncServiceParcela.baseSyncPolicy(this.parcelasPUT));
    }

    private defineParcelasPOST() {
        this.parcelasPOST
            .fetch(HttpRequestType.POST, 'generic/parcelas');

        this.apiParcela
            .registerRequest(this.parcelasPOST);

        this.syncServiceParcela
            .register(this.syncServiceParcela.baseSyncPolicy(this.parcelasPOST));
    }

    private defineParcelasDELETE() {
        this.parcelasDELETE
            .fetch(HttpRequestType.DELETE, 'parcelas/post');

        this.apiParcelaDelete
            .registerRequest(this.parcelasDELETE);

        this.syncServiceParcelaDelete
            .register(this.syncServiceParcelaDelete.baseSyncPolicy(this.parcelasDELETE));
    }


}
