<br>

<div style="width: auto; overflow: auto">
  <app-common-list-table
          [request]="getRequest"
          [minWidth]="750"
          [cols]="(canRegister || applicationType === 'cropgest') ? colsCanRegister : cols"
          [filtering]="filter"
          [globalVisualTransform]="globalVisualTransform"
          [globalConditionalStyle]="globalConditionalStyle"
          [actionButtonText]="!canRegister ? btnAnadir : ''"
          (actionButtonClick)="add()">

    <ng-template #thEnd>
      <th *ngIf="this.canRegister || this.applicationType === 'cropgest'">
        <p class="mb-0 d-flex justify-content-center">Estado</p>
      </th>
      <th>
        <p class="d-flex justify-content-center"
           style="margin-bottom: 0">Acciones</p>
      </th>
    </ng-template>

    <ng-template #tdEnd let-data>
      <td *ngIf="this.canRegister || this.applicationType === 'cropgest'">
        <div class="d-flex justify-content-center align-items-center">
            <button [ngStyle]="getStyles('acceso_plataforma', data.data)" 
                class="d-flex justify-content-center align-items-center"
                (click)="toggleActive(data.data)"
                style="
                    height: 20px;
                    width: fit-content;
                    padding: 0 5px;
                    border-radius: 3px;
                    margin-right: 10px;
                    color: white;
                    font-weight: bold;
                    cursor: pointer;">
                    {{data.data?.acceso_plataforma === '1' ? 'Activo' : data.data?.acceso_plataforma === '0' ? 'Inactivo' : 'Pendiente'}}
            </button>
        </div>
      </td>
      <td>
        <app-action-buttons
          (editRowButtonEvent)="edit(data.data)"
          (deleteRowButtonEvent)="show('delete', data.data)"
          (showRowButtonEvent)="show('info', data.data)">
        </app-action-buttons>
      </td>
    </ng-template>
  </app-common-list-table>
</div>

<p-dialog *ngIf="canShow['info']" header="Detalle del registro" [(visible)]="canShow['info']" (onShow)="scrollToTop()">
  <div style="width: 80vw !important">
    <div *ngIf="canRegister">
      <h4>Datos de Cuenta</h4>
      <app-form-builder
        [fields]="formFieldsDatosCuenta"
        [distributionColumns]="3"
        [model]="model">
      </app-form-builder>
      <h4>Datos Personales</h4>
      <app-form-builder
        [fields]="formFieldsDatosPersonales"
        [distributionColumns]="3"
        [model]="model">
      </app-form-builder>  
    </div>
    <div *ngIf="!canRegister">
      <app-form-builder
        [fields]="formFields"
        [distributionColumns]="4"
        [model]="model">
      </app-form-builder>
    </div>
    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
    </div>
  </div>
</p-dialog>

<p-dialog *ngIf="canShow['delete']" header="Eliminar registro" [(visible)]="canShow['delete']" (onShow)="scrollToTop()">
  <div style="width: 80vw !important">
    <div *ngIf="canRegister">
      <h4>Datos de Cuenta</h4>
      <app-form-builder
        [fields]="formFieldsDatosCuenta"
        [distributionColumns]="3"
        [model]="model">
      </app-form-builder>
      <h4>Datos Personales</h4>
      <app-form-builder
        [fields]="formFieldsDatosPersonales"
        [distributionColumns]="3"
        [model]="model">
      </app-form-builder>  
    </div>
    <div *ngIf="!canRegister">
      <app-form-builder
        [fields]="formFields"
        [distributionColumns]="4"
        [model]="model">
      </app-form-builder>
    </div>
    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
      <app-request-button
        (click)="deleteRegister()"
        [request]="deleteRequest"
        [mode]="deleteRequestButtonType">
      </app-request-button>
    </div>
    <br>
    <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading"
       style="color: red; text-align: center">
      ¿Está seguro de eliminar este registro?
    </p>
  </div>
  <br>
</p-dialog>
