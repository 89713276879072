import { features } from './features'; 

features.applicationType = 'agrogest';

// FITOGEST
features.showAnalisis = true;
features.showPhCaldo = true;
features.showPartida = true;
features.puedeImprimir = true;
features.showRegistroTratamiento = true;
features.showEnviarCorreo = true;
features.showEnviarCorreoVisitas = false;
features.showTareasBio = true;
features.showMeteoBtn = true;
features.showVentusky = true;
features.showTratamientosAllProductos = false;
features.showVisitasCamposPersonalizados = true;
features.showTareasOtrosMultiple = true;

// AGROGEST
features.showAplicadoresFichajes = true;
features.showAplicadoresPartes = true;
features.showTrabajadoresCargos = true;
features.showTareasOtros = true;
features.showTareasSiembra = true;
features.showCostes = true;
features.showCostesOtros = true;
features.showCostesProductos = true;
features.showCostesTareasFertiCsv = true;
features.showCostesTareasOtrasCsv = true;
features.hasClients = true;
features.showStock = true;
features.showStockProveedor = false;
features.showStockEnvase = false;
features.showStockSemilla = true;
features.showSectorPlantas = false;
features.fertilizantesNombreRequired = true;
features.fertilizantesUdsMedidaRequired = true;
features.showVisitas = true;
features.showVisitasConsultas = true;
features.showHistoricoFT = true;
features.showExepcionales = false;
features.showOtrasDenominaciones = false;
features.showRecetaAgrogest = true;
features.showRecetaFertiAgrogest = true;
features.showStockReto = false;
features.showReto = false;
features.showStockTipoDeDato = true;

features.showMapaLabels = true;
features.showMapaTratamientos = true;
features.showTareasSiembraProductos = true;
features.showProductosNumReg = true;
features.showTareasFertiSectorMultiple = true;

// BUSINESS INTELLIGENCE
features.showEstadisticas = true;
features.showUsuariosLogs = true;
features.showUsuariosFirma = true;

export const environment = {
    production: true,
    v: '2',
    serverUrl: 'https://agrosol.agrogest.com/',
    logoUrl: 'assets/logo_agrosol.png',
    appName: 'agrosol',
    features: features
};
