import { Component, Input } from '@angular/core';
import moment from 'moment';
import { MessageService } from 'primeng/api';

import type { OnInit } from '@angular/core';
import type { Dataset, GraficaData } from '../../grafica-manez.interface';

@Component({
    selector: 'app-grafica-velocidad-manez-lozano',
    templateUrl: './grafica-velocidad.component.html',
    styleUrls: ['./grafica-velocidad.component.scss'],
    providers: [MessageService]
})
export class GraficaVelocidadManezLozanoComponent implements OnInit {

    @Input() grafica_vel: GraficaData = {
        labels: [],
        datasets: [{ label: '', data: [] }]
    };
    @Input() grafica_pressure: GraficaData = {
        labels: [],
        datasets: [{ label: '', data: [] }]
    };
    @Input() grafica_rpm: GraficaData = {
        labels: [],
        datasets: [{ label: '', data: [] }]
    };
    @Input() grafica_flow: GraficaData = {
        labels: [],
        datasets: [{ label: '', data: [] }]
    };
    @Input() grafica_dose: GraficaData = {
        labels: [],
        datasets: [{ label: '', data: [] }]
    };

    public data: GraficaData = {
        labels: [],
        datasets: [{ label:'', data: [], fill: false, borderColor: '' }]
    };
    public aux_chart_speed: GraficaData = {
        labels: [],
        datasets: [{ label:'', data: [] }]
    };

    public aux_grafica_pressure: GraficaData = {
        labels: [],
        datasets: [{ label: '', data: [] }]
    };

    public aux_grafica_rpm: GraficaData = {
        labels: [],
        datasets: [{ label: '', data: [] }]
    };

    public aux_grafica_flow: GraficaData = {
        labels: [],
        datasets: [{ label: '', data: [] }]
    };
    public aux_grafica_dose: GraficaData = {
        labels: [],
        datasets: [{ label: '', data: [] }]
    };

    public countChart = 0;
    public max_chart_pages = 1;
    public elments_per_page = 1440;


    public options = {
        responsive: false,
        maintainAspectRatio: false
    };

    public max_multipler = 3;
    constructor() {

        /*  this.data = {
             labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
             datasets: [
                 {
                     label: 'First Dataset',
                     data: [65, 59, 80, 81, 56, 55, 40],
                     fill: false,
                     borderColor: '#4bc0c0'
                 },
                 {
                     label: 'Second Dataset',
                     data: [28, 48, 40, 19, 86, 27, 90],
                     fill: false,
                     borderColor: '#565656'
                 }
             ]
         } */
    }

    ngOnInit() {

        const labels: number[] = [];

        /*  this.aux_chart_speed = {...this.grafica_vel};
         this.aux_grafica_pressure = {...this.grafica_pressure};
         this.aux_grafica_rpm = {...this.grafica_rpm};
         this.aux_grafica_flow = {...this.grafica_flow};
         this.aux_grafica_dose = {...this.grafica_dose}; */



        const intervalo = setInterval(async() => {

            if (this.grafica_vel.datasets == null || (this.grafica_vel.datasets as Dataset).data.length === 0) {
            } else {

                for (let index = 0; index < Object.keys(this.grafica_vel.datasets).length; index++) {
                    labels.push((index + 1));
                }
                //console.log(this.aux_chart_speed.datasets);
                this.splitContent();

            }

            clearInterval(intervalo);
        }, 1000);
        console.log(this.data);
    }

    selectData() {
        /*
            this.messageService.add({
                    severity: 'info', 
                    summary: 'Data Selected', 
                    'detail': this.data.datasets[event.element._datasetIndex].data[event.element._index]
            });
        */
    }


    async splitContent() {
        //await this.compareHours(this.grafica_vel.labels);
        this.max_chart_pages = parseInt((this.grafica_vel.labels.length / this.elments_per_page).toString(), 10);
        if ( this.max_chart_pages === 0) {
            this.max_chart_pages = 1;
        }
        let limit = this.elments_per_page;
        if (limit > this.grafica_vel.labels.length) { limit = this.grafica_vel.labels.length; }
        let val2Speed = 0;
        let validSpeed = 0;
        let val2Pressure = 0;
        let val2Rpm = 0;
        let val2Flow = 0;
        let val2Dose = 0;
        let validDose = 0;

        for (let index = 0; index < limit; index++) {
            const label = this.grafica_vel.labels[index];

            let dataSpeed = (this.grafica_vel.datasets as Dataset).data[index];
            let dataPressure = (this.grafica_pressure.datasets as Dataset).data[index];
            let dataRpm = (this.grafica_rpm.datasets as Dataset).data[index];
            let dataFlow = (this.grafica_flow.datasets as Dataset).data[index];
            let dataDose = (this.grafica_dose.datasets as Dataset).data[index] ? (this.grafica_dose.datasets as Dataset).data[index] : 0;
            //console.log('index: ' + index + "/" + limit + " dose: " + data_dose);


            if (index >= 2) {
                /* if (val2_speed != 0 && data_speed != 0) {
                    if (data_speed > (val2_speed * this.max_multipler)) data_speed = val2_speed;
                } */
                if (dataSpeed) {
                    if (val2Speed) {
                        validSpeed = val2Speed;
                        if (dataSpeed > (validSpeed * this.max_multipler)) {
                            dataSpeed = val2Speed;
                        }
                    } else if (validSpeed !== 0) {
                        val2Speed = validSpeed;
                        if (dataSpeed > (validSpeed * this.max_multipler)) {
                            dataSpeed = val2Speed;
                        }
                    }
                    /*  valid_speed = val2_speed;
                    if (data_speed > (valid_speed * this.max_multipler)) {
                        data_speed = val2_speed;
                    } */
                }

                if (val2Pressure && dataPressure) {
                    if (dataPressure > (val2Pressure * this.max_multipler)){
                        dataPressure = val2Pressure;
                    }
                }

                if (val2Rpm && dataRpm) {
                    if (dataRpm > (val2Rpm * this.max_multipler)){
                        dataRpm = val2Rpm;
                    }
                }

                if (val2Flow && dataFlow) {
                    if (dataFlow > (val2Flow * this.max_multipler)) {
                        dataFlow = val2Flow;
                    }
                }

                if (dataDose) {
                    if (val2Dose) {
                        validDose = val2Dose;
                        if (dataDose > (validDose * this.max_multipler)) {
                            dataDose = val2Dose;
                        }
                    } else if (validDose) {
                        val2Dose = validDose;
                        if (dataDose > (validDose * this.max_multipler)) {
                            dataDose = val2Dose;
                        }
                    }
                }
            }

            val2Speed = dataSpeed ?? 0;
            val2Pressure = dataPressure ?? 0;
            val2Rpm = dataRpm ?? 0;
            val2Flow = dataFlow ?? 0;
            val2Dose = dataDose ?? 0;

            this.aux_chart_speed.labels.push(label ?? '');
            (this.aux_chart_speed.datasets as Dataset[])[0]?.data.push(dataSpeed ?? 0);
            (this.aux_grafica_pressure.datasets as Dataset[])[0]?.data.push(dataPressure ?? 0);
            (this.aux_grafica_rpm.datasets as Dataset[])[0]?.data.push(dataRpm ?? 0);
            (this.aux_grafica_flow.datasets as Dataset[])[0]?.data.push(dataFlow ?? 0);
            (this.aux_grafica_dose.datasets as Dataset[])[0]?.data.push(dataDose ?? 0);
        }
        

        this.data = {
            labels: this.aux_chart_speed.labels,
            datasets: [

                {
                    label: 'Velocidad (km/h)',
                    data: (this.aux_chart_speed.datasets as Dataset[])[0]?.data ?? [0],
                    fill: false,
                    borderColor: 'green'
                },
                {
                    label: 'Presión (bar)',
                    data: (this.aux_grafica_pressure.datasets as Dataset[])[0]?.data ?? [0],
                    fill: false,
                    borderColor: '#4bc0c0'
                },
                {
                    label: 'Rpm',
                    data: (this.aux_grafica_rpm.datasets as Dataset[])[0]?.data ?? [0],
                    fill: false,
                    borderColor: '#aa42f5'
                },
                {
                    label: 'Dosis',
                    data: (this.aux_grafica_dose.datasets as Dataset[])[0]?.data ?? [0],
                    fill: false,
                    borderColor: 'brown'
                },
                {
                    label: 'Caudal (l/min)',
                    data: (this.aux_grafica_flow.datasets as Dataset[])[0]?.data ?? [0],
                    fill: false,
                    borderColor: 'orange'
                }
            ],
        };
    }

    replaceContent(operation: string) {
        let posicionInicial = 0;
        let posicionFinal = 0;

        if (operation === 'begin') {
            this.countChart = 0;
            if (this.countChart <= 0) {
                //alert('Es la primera posición');
                this.countChart = 0;
                posicionInicial = 0;
                posicionFinal = (this.elments_per_page);
            }

            if (posicionFinal > this.grafica_vel.labels.length) {
                posicionFinal = this.grafica_vel.labels.length;
            }
        }

        if (operation === 'previous') {
            this.countChart--;
            if (this.countChart <= 0) {
                //alert('Es la primera posición');
                this.countChart = 0;
                posicionInicial = 0;
                posicionFinal = (this.elments_per_page);
            } else {
                posicionInicial = (this.elments_per_page * (this.countChart + 1));
                posicionFinal = (this.elments_per_page * (this.countChart + 2));
            }


            if (posicionFinal > this.grafica_vel.labels.length) {
                posicionFinal = this.grafica_vel.labels.length;
                // alert('no hay mas posiciones');
            }
        }

        if (operation === 'next') {
            this.countChart++;
            if (this.countChart <= 0) {
                this.countChart = 0;
                posicionInicial = 0;
                posicionFinal = this.elments_per_page;
            } else {
                posicionInicial = (this.elments_per_page * (this.countChart + 1));
                posicionFinal = (this.elments_per_page * (this.countChart + 2));
            }


            if (posicionFinal > this.grafica_vel.labels.length) {
                posicionFinal = this.grafica_vel.labels.length;
                //alert('no hay mas posiciones');
            } else {

            }
        }

        if (operation === 'final') {
            
            this.countChart = (this.max_chart_pages - 1);
           
            posicionInicial = (this.elments_per_page * (this.countChart + 1));
            posicionFinal = (this.elments_per_page * (this.countChart + 2));
            


            if (posicionFinal > this.grafica_vel.labels.length) {
                posicionFinal = this.grafica_vel.labels.length;
                //alert('no hay mas posiciones');
            } else {

            }
        }

        this.aux_chart_speed.labels = [];
        (this.aux_chart_speed.datasets as Dataset).data = [];
        (this.aux_grafica_pressure.datasets as Dataset).data = [];
        (this.aux_grafica_rpm.datasets as Dataset).data = [];
        (this.aux_grafica_dose.datasets as Dataset).data = [];
        
        /* console.log('posicion_inicial', posicion_inicial);
        console.log('posicion_final', posicion_final); */
        let val2Speed = 0;
        let validSpeed = 0;
        let val2Pressure = 0;
        let val2Rpm = 0;
        let val2Flow = 0;
        let val2Dose = 0;
        let validDose = 0;
        for (let index = posicionInicial; index < posicionFinal; index++) {
            const label = this.grafica_vel.labels[index];

            let dataSpeed = (this.grafica_vel.datasets as Dataset).data[index];
            let dataPressure = (this.grafica_pressure.datasets as Dataset).data[index];
            let dataRpm = (this.grafica_rpm.datasets as Dataset).data[index];
            let dataFlow = (this.grafica_flow.datasets as Dataset).data[index];
            let dataDose = (this.grafica_dose.datasets as Dataset).data[index] ? (this.grafica_dose.datasets as Dataset).data[index] : 0;
            if (index >= 2) {
                /*      if (val2_speed != 0 && data_speed != 0) {
                    if (data_speed > (val2_speed * this.max_multipler)) data_speed = val2_speed;
                } */
                if (dataSpeed) {
                    if (val2Speed) {
                        validSpeed = val2Speed;
                        if (dataSpeed > (validSpeed * this.max_multipler)) {
                            dataSpeed = val2Speed;
                        }
                    } else if (validSpeed !== 0) {
                        val2Speed = validSpeed;
                        if (dataSpeed > (validSpeed * this.max_multipler)) {
                            dataSpeed = val2Speed;
                        }
                    }
                    /*  valid_speed = val2_speed;
                    if (data_speed > (valid_speed * this.max_multipler)) {
                        data_speed = val2_speed;
                    } */
                }

                if (val2Pressure && dataPressure) {
                    if (dataPressure > (val2Pressure * this.max_multipler)) {
                        dataPressure = val2Pressure;
                    }
                }

                if (val2Rpm && dataRpm) {
                    if (dataRpm > (val2Rpm * this.max_multipler)) {
                        dataRpm = val2Rpm;
                    }
                }

                if (val2Flow && dataFlow) {
                    if (dataFlow > (val2Flow * this.max_multipler)) {
                        dataFlow = val2Flow;
                    }
                }

                if (dataDose) {
                    if (val2Dose) {
                        validDose = val2Dose;
                        if (dataDose > (validDose * this.max_multipler)) {
                            dataDose = val2Dose;
                        }
                    } else if (validDose) {
                        val2Dose = validDose;
                        if (dataDose > (validDose * this.max_multipler)) {
                            dataDose = val2Dose;
                        }
                    }
                }
            }

            val2Speed = dataSpeed ?? 0;
            val2Pressure = dataPressure ?? 0;
            val2Rpm = dataRpm ?? 0;
            val2Flow = dataFlow ?? 0;
            val2Dose = dataDose ?? 0;

            this.aux_chart_speed.labels.push(label ?? '');
            (this.aux_chart_speed.datasets as Dataset).data.push(dataSpeed ?? 0);
            (this.aux_grafica_pressure.datasets as Dataset).data.push(dataPressure ?? 0);
            (this.aux_grafica_rpm.datasets as Dataset).data.push(dataRpm ?? 0);
            (this.aux_grafica_flow.datasets as Dataset).data.push(dataFlow ?? 0);
            (this.aux_grafica_dose.datasets as Dataset).data.push(dataDose ?? 0);
        }
       


        this.data = {
            labels: this.aux_chart_speed.labels,
            datasets: [

                {
                    label: 'Velocidad (km/h)',
                    data: (this.aux_chart_speed.datasets as Dataset).data,
                    fill: false,
                    borderColor: 'green'
                },
                {
                    label: 'Presión (bar)',
                    data: (this.aux_grafica_pressure.datasets as Dataset).data,
                    fill: false,
                    borderColor: '#4bc0c0'
                },
                {
                    label: 'Rpm',
                    data: (this.aux_grafica_rpm.datasets as Dataset).data,
                    fill: false,
                    borderColor: '#aa42f5'
                },
                {
                    label: 'Dosis',
                    data: (this.aux_grafica_dose.datasets as Dataset).data,
                    fill: false,
                    borderColor: 'brown'
                },
                {
                    label: 'Caudal (l/min)',
                    data: (this.aux_grafica_flow.datasets as Dataset).data,
                    fill: false,
                    borderColor: 'orange'
                }
            ],
        };
    }

    compareHours(labels: string[]) {
        return new Promise(resolve => {
            //console.log('labels', labels);

            const beginningTime = labels[0]?.split(':') ?? '';
            // console.log(beginningTime[0]);
            // console.log(beginningTime[1]);
            // console.log(beginningTime[2]);
            // console.log(beginningTime);



        
            //var b = moment([09,30,00], "HH:mm:ss")
            //a.diff(b, 'hours')

            for (let index = 0; index < labels.length; index++) {
                const element = labels[index];
                //console.log('labels');
                //console.log(element);
                // console.log('element');
                // console.log( parseInt(element[0].split(":")[0]));
                const endOfTime = element;
                                
                const diff = moment( endOfTime, 'hh:mm:ss')
                    .subtract(beginningTime[0], 'hours')
                    .subtract(beginningTime[1], 'minutes')
                    .subtract(beginningTime[2], 'seconds');
                // console.log('b: ', b);
                // console.log(a.diff(b, 'hours'));
                
                
                if ( diff.hours() >= 2) {
                    this.elments_per_page = index;
                    resolve(true);
                    index = labels.length;
                }

                if ((index + 1) >= labels.length) {
                    resolve(true);
                }
            }
        });


        //console.log(beginningTime.isBefore(endTime));
    }



}



export function fillLabels(data: string[]) {
    const labels = [];
    for (let index = 0; index < data.length; index++) {
        labels.push(data[index]);
        if ((index + 1) >= data.length) {

        }
    }
}