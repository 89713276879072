import { Component } from '@angular/core';
import {TrabajadoresApiService} from '../../../../service/api/trabajadores-api.service';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {Utils} from '../../../../common/utils';
import { Filtering } from '../../../../service/filtering/filtering';
import { environment } from '../../../../../environments/environment';
import moment, { Moment } from 'moment';
import { BaseView } from '../../../../view/base-view';
import html2canvas from 'html2canvas';

import type { OnInit, AfterViewInit } from '@angular/core';
import { Fichaje } from 'projects/fichajes/src/app/models/Fichajes.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { PartesInterface } from './partes.interface';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'app-partes',
    templateUrl: './partes.component.html',
    styleUrls: ['./partes.component.scss']
})
export class PartesComponent extends BaseView<Fichaje> implements OnInit, AfterViewInit {

    public appName = environment.appName;
    public cunyoUrl = environment.features.cunyoUrl;
    public empresaName = environment.features.empresaName;
    public empresaCIF = environment.features.empresaCIF;
    public empresaCCC = environment.features.empresaCCC;
    public empresaLocation = environment.features.empresaLocation;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public showTrabajadoresCuadrillas = environment.features.showTrabajadoresCuadrillas;
    public showFormatHourMinute = environment.features.showFormatHourMinute;

    public filter: Filtering<Fichaje> = new Filtering<Fichaje>();
    public editable = true;
    public partes: PartesInterface[] = [];

    public date = new Date();
    public es = Utils.es;

    public almacenFilter = '';

    public diasMes: string[] = [
        '01', '02', '03', '04', '05',
        '06', '07', '08', '09', '10',
        '11', '12', '13', '14', '15',
        '16', '17', '18', '19', '20',
        '21', '22', '23', '24', '25',
        '26', '27', '28', '29', '30',
        '31'
    ];

    public showRegistro = false;
    public imprimiendoRegistro = false;
    public registro: PartesInterface = {fichajes: []};

    public cols: ColInterface[] = [
        {field: 'nombre', header: 'Nombre'},
        {field: 'nombre_cuadrilla', header: 'Cuadrilla', visible: this.showTrabajadoresCuadrillas},
        {field: 'precio', header: 'Precio'},
        {field: '01', header: '1', sort: false},
        {field: '02', header: '2', sort: false},
        {field: '03', header: '3', sort: false},
        {field: '04', header: '4', sort: false},
        {field: '05', header: '5', sort: false},
        {field: '06', header: '6', sort: false},
        {field: '07', header: '7', sort: false},
        {field: '08', header: '8', sort: false},
        {field: '09', header: '9', sort: false},
        {field: '10', header: '10', sort: false},
        {field: '11', header: '11', sort: false},
        {field: '12', header: '12', sort: false},
        {field: '13', header: '13', sort: false},
        {field: '14', header: '14', sort: false},
        {field: '15', header: '15', sort: false},
        {field: '16', header: '16', sort: false},
        {field: '17', header: '17', sort: false},
        {field: '18', header: '18', sort: false},
        {field: '19', header: '19', sort: false},
        {field: '20', header: '20', sort: false},
        {field: '21', header: '21', sort: false},
        {field: '22', header: '22', sort: false},
        {field: '23', header: '23', sort: false},
        {field: '24', header: '24', sort: false},
        {field: '25', header: '25', sort: false},
        {field: '26', header: '26', sort: false},
        {field: '27', header: '27', sort: false},
        {field: '28', header: '28', sort: false},
        {field: '29', header: '29', sort: false},
        {field: '30', header: '30', sort: false},
        {field: '31', header: '31', sort: false},
        {field: 'horas_mes', header: 'h/mes'},
        {field: 'precio_mes', header: '€/mes'},
    ];
    totalHoras: Moment | string = moment();
    auxTotalHoras: Moment = moment();


    constructor(public trabajadoresApi: TrabajadoresApiService,
        private dashboardEvent: DashboardService) {
        super(dashboardEvent, trabajadoresApi.fichajesGET, trabajadoresApi.fichajesDELETE);
    }

    ngOnInit() {
        this.dashboardEvent.setSubmenuItem('partes');

        this.trabajadoresApi.partes.response(res => {
            this.partes = res;
        });

        if (this.appName === 'naturfrut') {
            this.filter.addFilter((copy: TreeNode<Fichaje>[] | void) => (copy as TreeNode<Fichaje>[]).filter(row => {
                const fichaje = row.data as Fichaje;
                switch (this.almacenFilter) {
                case 'es-almacen':
                    return [true, '1', 1].includes(fichaje.es_almacen ?? '');
                case 'no-es-almacen':
                    return [null, undefined, '0', 0].includes(fichaje.es_almacen);
                default:
                    return true;
                }
            }));
        }
    }

    ngAfterViewInit() {
        this.trabajadoresApi.partes.response(() => {
            this.updateFooter();
        });
    }

    public buscar(){
        const date = moment(this.date);
        this.trabajadoresApi.partes
            .safePerform({month: date.format('MM'), year: date.format('YYYY')} as never);
    }

    public override sum(field: string) {
        let sum = 0;

        (this.filter.value || []).forEach(it => {
            if ((it as Fichaje)[field]) {
                // if ( !isNaN(parseInt(field)) ){
                //   console.log('it', it[field]  );
                //   console.log('field', [field]  );
                //   let horas = moment(it[field], 'hh:mm').hours() + (moment(it[field], 'hh:mm').minutes() / 60);
                //   console.log('horas', horas  );
                // }
                sum += parseFloat((it as Fichaje)[field] as string);
        
            }
        });

        this.cols.forEach(it => {
            if (it.field === field) {
                if ( this.showFormatHourMinute  ){
                    if (field === 'precio_mes') { 
                        it.footer = Utils.decimalFormat(sum + '', 2);
                    } else {
                        it.footer = Utils.decimalFormat(sum + '', 0);
                    }
                } else {
                    it.footer = Utils.decimalFormat(sum + '', 0);
                    if (field === 'precio_mes') {
                        it.footer = Utils.decimalFormat(it.footer.toString().replace('.', ''), 2);
                    }
                }
       
            }
        });
 
    }

    public override updateFooter() {
        this.cols.forEach(it => {
            if (!['finca', 'nombre'].includes(it.field)) {
                this.sum(it.field);
            }
        });
    }

    public globalConditionalStyle(value: string, col: ColInterface): ColStylesInterface {
        if (Utils.isNumber(value)) {
            return {
                fontSize: '.6rem',
                textAlign: 'center'
            };
        }

        if (col.field === 'precio' || col.field === 'horas_mes' || col.field === 'precio_mes') {
            return {
                textAlign: 'right',
                paddingRight: '40px'
            };
        }
        return {};
    }

    public globalVisualTransform(value: string, col: ColInterface) {
        if ((col.field === col.header) || (col.field === ('0' + col.header))) {
            if (value && value.toString().includes('.')) {
                return value;
            }
        }
        if (col.field === 'horas_mes') {
            return ('0' + Math.floor(+value)).slice(-2) + ':' + ((+value % 1) * 60 + '0').slice(0, 2);
        }

        if (Utils.isNumber(value?.toString())) {
            if (['precio', 'precio_mes', 'horas_mes'].includes(col.field)) {
                return Utils.decimalFormat(value, 2, '.', ',', 2);
            }
        }
        return value;
    }

    public generarRegistro(data: PartesInterface){
        if ( this.showFormatHourMinute ){
            this.generarRegistroHourMinute(data);
        } else {
            this.generarRegistroDecimal(data);
        }
    }

    public generarRegistroHourMinute(data: PartesInterface) {
        this.registro = data;
        if (this.appName === 'naturfrut') {
            this.empresaCCC = [true, 1, '1'].includes(this.registro.es_almacen ?? '') ? '46110260207' : '46133774926';
        }
        this.trabajadoresApi.fichajesGET.toPromise({getRegistro: moment(this.date).format('MM/YYYY')} as never).then(response => {
            const fichajes: Fichaje[] = response;
            const toPush: Fichaje[] = [];
      
        
            fichajes.filter(it => it.nif === data.nif)
                .forEach(it => {
                    let d = moment(it.fecha, 'DD/MM/YYYY').date().toString();
                    if (d.length === 1) { d = '0' + d; }

                    toPush[+d] = {} as Fichaje;
                    for (const key of ['horaEntrada', 'horaSalida', 'horaEntradaTarde', 'horaSalidaTarde']) {
                        if (moment(it[key], 'HH:mm').isValid()) {
                            (toPush[+d] as Fichaje)[key] = it[key] ?? '';
                        } else {
                            (toPush[+d] as Fichaje)[key] = '';
                        }
                    }
                });
      
            this.registro.fichajes = toPush;
      
            return;
        }).then( () => {
            this.registro.empresa = this.appName.toLocaleUpperCase();
            this.registro.fecha = moment(this.date).toObject();
            this.registro.fecha_siguiente = moment(this.date).add(1, 'months');
            this.registro.month = this.registro.fecha_siguiente.format('MMMM').toLocaleUpperCase();
            this.registro.year = this.registro.fecha_siguiente.format('YYYY');
  
            this.registro.total = 0;
            this.registro.horas = '00:00';
            this.totalHoras = moment(new Date('October 1, 2014 00:00:00'));
            this.auxTotalHoras = moment(new Date('October 1, 2014 00:00:00'));
            this.diasMes.forEach((dia, index) => {
                //console.log(this.registro);
        
                if ( this.registro[dia] != null && (this.registro[dia] as string).length > 0) {
          
                    let minutes: string | number = '';
                    if ( this.showFormatHourMinute) {
                        const hourMinute: string[] = this.registro[dia] ? (this.registro[dia] as number).toString().split('.') : ['00:00'];
  
                        if (hourMinute[1] == null ){
                            hourMinute[1] = '00';
                        } else {
  
                            hourMinute[1]  = '0.' + hourMinute[1];
            
                            minutes = parseFloat( hourMinute[1] ) * (60) ;
                            minutes = parseFloat(minutes.toFixed(2)) ;
          
                            if (Math.round(minutes).toString().length === 1) {
                                minutes = '0' + Math.round(minutes).toString();
  
                            } else {
                                minutes = Math.round(minutes).toString();
  
                            }
          
                        }
  
                        if ( minutes.length === 0  ){
                            minutes = '00';
                        }
                        if ( !(this.registro[dia] as string).includes(':') ){

                            if ( this.registro.fichajes != null && this.registro.fichajes[+dia] != null ) {
                                const horaEntrada = this.registro.fichajes[+dia]?.horaEntrada ?? '';
                                const horaEntradaTarde = this.registro.fichajes[+dia]?.horaEntradaTarde ?? '';
                                const horaSalida = this.registro.fichajes[+dia]?.horaSalida ?? '';
                                const horaSalidaTarde = this.registro.fichajes[+dia]?.horaSalidaTarde ?? '';
                                this.registro['dia_aux' + dia] = this.autofillHoras(
                                    horaEntrada,
                                    horaSalida,
                                    horaEntradaTarde,
                                    horaSalidaTarde
                                );
                
                            } else {
                                this.registro['dia_aux' + dia] = hourMinute[0] + ':' + minutes;
                            }
  
                            //console.log(this.registro['dia_aux' + dia] );
              
                        }
            
                        if (this.registro.horas == null ) {
                            this.registro.horas = this.registro['dia_aux' + dia]?.toString() ?? '';
                        } else {
                            //if (this.appName === 'fotoagricultura'){
                            this.totalHoras = moment(this.totalHoras, 'HH:mm')
                                .add(this.registro[dia]?.toString().split(':')[0], 'h')
                                .add(this.registro[dia]?.toString().split(':')[1], 'm');
              
                        }
  
                    }  
                } else{ 
                    if (this.registro.total !== undefined) {
                        this.registro.total += parseFloat(parseFloat((this.registro[dia] || 0).toString()).toFixed(2));
                    }
                }
                if ( (index + 1 ) >= this.diasMes.length){
                    let differencia = moment(this.totalHoras).diff(this.auxTotalHoras);
      
                    let minutos = moment(this.totalHoras).format('mm');
  
                    if (minutos.toString().length === 0) {
                        minutos = '00';
                    }
  
                    if (minutos.toString().length === 1) {
                        minutos = '0' + minutos.toString();
                    }
  
                    differencia = differencia / (1000 * 60 * 60);
  
                    differencia = Math.abs(Math.floor(differencia));
  
                    this.totalHoras = differencia + ':' + minutos;
  
                }
            });
  
            const elements = document.getElementsByClassName('ui-dialog-content ui-widget-content');
            setTimeout(() => {
                this.showRegistro = true;
            }, 100);
  
            setTimeout(() => {
                if (elements[0]) {
                    elements[0].scrollTop = 0;
                }
            }, 200);
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );

   
    }

    public generarRegistroDecimal(data: PartesInterface) {
        this.registro = data;
        if (this.appName === 'naturfrut') {
            this.empresaCCC = [true, 1, '1'].includes(this.registro.es_almacen ?? '') ? '46110260207' : '46133774926';
        }
        this.trabajadoresApi.fichajesGET.toPromise({getRegistro: moment(this.date).format('MM/YYYY')} as never).then(response => {
            const fichajes: Fichaje[] = response;
            const toPush: Fichaje[] = [];

            fichajes.filter(it => it.nif === data.nif)
                .forEach(it => {
                    let d = moment(it.fecha, 'DD/MM/YYYY').date().toString();
                    if (d.length === 1) { d = '0' + d; }

                    toPush[+d] = {} as Fichaje;
                    for (const key of ['horaEntrada', 'horaSalida', 'horaEntradaTarde', 'horaSalidaTarde']) {
                        if (moment(it[key], 'HH:mm').isValid()) {
                            (toPush[+d] as Fichaje)[key] = it[key] ?? '';
                        } else {
                            (toPush[+d] as Fichaje)[key] = '';
                        }
                    }
                });
      
            this.registro.fichajes = toPush;
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );

        this.registro.empresa = this.appName.toLocaleUpperCase();
        this.registro.fecha = moment(this.date).toObject();
        this.registro.fecha_siguiente = moment(this.date).add(1, 'months');
        this.registro.month = this.registro.fecha_siguiente.format('MMMM').toLocaleUpperCase();
        this.registro.year = this.registro.fecha_siguiente.format('YYYY');

        this.registro.total = 0;
        this.diasMes.forEach(dia => {
            if (this.registro.total !== undefined) {
                this.registro.total += parseFloat((this.registro[dia] || 0).toString());
            }
        });

        const elements = document.getElementsByClassName('ui-dialog-content ui-widget-content');
        setTimeout(() => {
            this.showRegistro = true;
        }, 100);

        setTimeout(() => {
            if (elements[0]) {
                elements[0].scrollTop = 0;
            }
        }, 200);
    }

    public imprimirRegistro() {
        this.imprimiendoRegistro = true;
        html2canvas(document.getElementById('html2canvasDiv') ?? new HTMLElement(), { scale: 2 }).then(canvas => {
            const downloadReceta = document.getElementById('downloadReceta') as HTMLAnchorElement;
            downloadReceta.href = canvas.toDataURL('image/png');
            downloadReceta.download = 'Registro.png';
            downloadReceta.click();
            this.imprimiendoRegistro = false;
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );
    }

    public formChanges(tag: string) {
        if (tag === 'es-almacen') {
            if (this.almacenFilter !== 'es-almacen') {
                this.almacenFilter = 'es-almacen';
            } else {
                this.almacenFilter = '';
            }
        }

        if (tag === 'no-es-almacen') {
            if (this.almacenFilter !== 'no-es-almacen') {
                this.almacenFilter = 'no-es-almacen';
            } else {
                this.almacenFilter = '';
            }
        }

        this.filter.filter();
    }


    fixNumberHourMinute( hora: string ){
        if ( hora != null && hora.length > 0 ) {
            const fixHour = hora.split(':');
            if (fixHour[0] && fixHour[0].length === 1 ){
                fixHour[0] = '0' + fixHour[0];
            }

            if (fixHour[1] && fixHour[1].length === 1 ) {
                fixHour[1] = '0' + fixHour[0];
            }
            return fixHour.toString();
        }
        return '';
    }

    public autofillHoras( horaEntrada: string, horaSalida: string, horaEntradaTarde: string, horaSalidaTarde: string) {

        let horasTotal = '';
        let hourMinute = '';

      
        const horaEntradaFormateada = moment(horaEntrada || '00:00', 'HH:mm').format('HH:mm');
        const horaSalidaFormateada = moment(horaSalida || '00:00', 'HH:mm').format('HH:mm');
        const horaEntradaTardeFormateada = moment(horaEntradaTarde || '00:00', 'HH:mm').format('HH:mm');
        const horaSalidaTardeFormateada = moment(horaSalidaTarde || '00:00', 'HH:mm').format('HH:mm');
   
      
        let diffManana = '';
        let diffTarde = '';
      
        let checkMorning = false;
        let checkAfter = false;
        if (horaEntrada && horaSalida) {
            diffManana = this.formatoHorasMinutos( horaEntradaFormateada, horaSalidaFormateada );
            checkMorning = true;
        }
        if (horaEntradaTarde && horaSalidaTarde) {
            diffTarde = this.formatoHorasMinutos( horaEntradaTardeFormateada, horaSalidaTardeFormateada);
            checkAfter = true;
        }

        if (checkMorning) { 
            horasTotal =  diffManana;       
        }

        if (checkAfter){ 
            horasTotal = diffTarde;
        }

        if ( checkMorning && checkAfter) {
            horasTotal =  moment(diffTarde, 'HH:mm').add(diffManana.split(':')[0], 'h').add(diffManana.split(':')[1], 'm').format('HH:mm');
        }

        hourMinute = horasTotal;
        return hourMinute;
      
   
    }

    formatoHorasMinutos(horaEntrada: string, horaSalida: string) {
        let timeStr: string | string[] = horaEntrada;
        timeStr = timeStr.split(':');

        const h = timeStr[0] ?? '',
            m = timeStr[1] ?? '';

        const newTime = moment( horaSalida + ' 2021-4-5')
            .subtract({'hours': +h, 'minutes': +m})
            .format('hh:mm');


        // console.log({str});
        return newTime;
    }
}
