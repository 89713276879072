import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageManagerService {
    private parcelasRoutes = [
        '/dashboard/parcelas',
        '/dashboard/crear-parcela',
        '/dashboard/editar-parcela',
        '/dashboard/duplicar-parcela'
    ];

    constructor(private router: Router) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.handleRouteChange(event.url);
            }
        });
    }

    private handleRouteChange(currentUrl: string): void {
        const isParcelasRoute = this.parcelasRoutes.some(route => currentUrl.startsWith(route));

        if (!isParcelasRoute) {
            localStorage.removeItem('selectedYear');
        }
    }
}
